import { DropdownLabelProps } from "./types";

import "./scss/index.scss";

export const DropdownLabel = ({ label, disabled }: DropdownLabelProps) => {
  if (!label) return null;
  return (
    <label className={`dropdown-label ${disabled ? "disabled" : ""}`}>
      {label}
    </label>
  );
};
