import { FC, memo, useContext } from "react";
import { AnimatePresence, motion } from "motion/react";

import { TableRowItemProps } from "./types";
import { TableRowActions } from "./components/TableRowActions";
import { TableRowIcon } from "./components/TableRowIcon";
import { TableContext } from "@/components/Table/context";
import { TableRowBulkAction } from "./components/TableRowBulkAction";
import { TableRowItemContent } from "./components/TableRowItemContent";
import { GENERIC_FADE_ANIMATION } from "@/constants";

import "./styles.scss";

export const TableRowItem: FC<TableRowItemProps> = memo(
  ({ record, expandStateHandler, expandState, rowHeaders, isDropDown }) => {
    const { actions, id } = useContext(TableContext);
    const key = id || "name";
    const recordId: number = record[key];

    return (
      <AnimatePresence>
        <>
          <motion.tr
            layout
            {...GENERIC_FADE_ANIMATION}
            onClick={() => isDropDown && expandStateHandler?.(recordId)}
            className={`table-row ${isDropDown ? "clickable" : ""}`}
          >
            <TableRowBulkAction {...{ record, idKey: key }} />
            <td>
              {isDropDown ? (
                <TableRowIcon {...{ expandState, recordId }} />
              ) : null}
            </td>
            <TableRowItemContent {...{ rowHeaders, record }} />
          </motion.tr>

          {actions ? <TableRowActions {...{ actions, record }} /> : null}
        </>
      </AnimatePresence>
    );
  }
);
