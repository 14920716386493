import { UserMailerDetails } from "../../../../types";

export const getActiveInactiveUsers = (
  records: UserMailerDetails[] | UserMailerDetails,
  filterActive: boolean = true
) => {
  if (Array.isArray(records))
    return records.filter((record) =>
      filterActive ? record.is_active : !record.is_active
    );

  if (filterActive ? records.is_active : !records.is_active) return [records];

  return [];
};
