import { FC } from "react";

import { Tabs } from "@/components/Tabs";
import { useSearchParams } from "@/hooks/useSearchParams";

export const AdminToolsTabs: FC = () => {
  const { currentUrlPath } = useSearchParams();
  const [tab, subTab] = currentUrlPath.slice(2);

  return (
    <Tabs
      activeTab={tab}
      activeSubTab={subTab}
      title={"Admin Tools"}
      tabs={[
        {
          title: "Users",
          subTabs: [
            {
              title: "User Administration",
              url: "/internal/admin-tools/users/user-administration/",
            },
            {
              title: "Group Admin",
              url: "/internal/admin-tools/users/group-admin/",
            },
            {
              title: "Import",
              url: "/internal/admin-tools/users/import/",
            },
            {
              title: "Export",
              url: "/internal/admin-tools/users/export/",
            },
          ],
        },
        {
          title: "Facilities",
          subTabs: [
            {
              title: "Active",
              url: "/internal/admin-tools/facilities/active",
            },
            {
              title: "Blocked",
              url: "/internal/admin-tools/facilities/blocked/",
            },
          ],
        },
        {
          title: "Product Codes",
          subTabs: [
            {
              title: "Searchable Codes",
              url: "/internal/admin-tools/product-codes/searchable-codes/",
            },
            {
              title: "Unused Codes",
              url: "/internal/admin-tools/product-codes/unused-codes/",
            },
            {
              title: "Import",
              url: "/internal/admin-tools/product-codes/import/",
            },
            {
              title: "Export",
              url: "/internal/admin-tools/product-codes/export/",
            },
          ],
        },
      ]}
    />
  );
};
