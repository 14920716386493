import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { InternalBlockedFacilitiesType } from "./types";
import { getBlockBy } from "./utils/getBlockBy";
import { getBlockByMoreCount } from "./utils/getBlockByMoreCount";
import { useModal } from "@/components/Table/hooks/useModal";
import { BlockListModal } from "@root/src/components/Table/components/BaseTable/hooks/useTableRows/components/TableRowItem/components/TableRowActions/components/GenerateActions/utils/useGetAction/actions/blockList/modal/BlockListModal";
import { FACILITY_PROFILE_URL } from "@root/src/constants";

const url: string = getURL("api:internal-blocked-facilities-list");
const options = getURL("api:internal-blocked-facilities-schema-options");

export const InternalBlockedFacilities: FC = () => {
  const { handleOpenModal, modal } = useModal(BlockListModal);

  return (
    <>
      {modal}
      <Table<InternalBlockedFacilitiesType>
        id="id"
        name="Blocked Facilities"
        searchable
        options={{ url: options }}
        tooltipHelper={{
          variant: "?",
          message:
            "Blocked facilities cannot transact with one another or view each other’s postings for Packages and/or Open Orders in the Marketplace.",
        }}
        headers={[
          {
            title: "FACILITY",
            key: "name",
            type: "url",
            properties: {
              newWindow: true,
              href: ({ id }) => `${FACILITY_PROFILE_URL}/${id}`,
            },
          },
          {
            title: "IS BLOCKING...",
            key: "is_blocking",
            type: "string",
            properties: {
              onRender: getBlockBy,
              notSortable: true,
              composite: {
                elements: [
                  {
                    key: "is_blocking",
                    type: "button",
                    properties: {
                      onRender: getBlockByMoreCount,
                      onClick: ({
                        value: blockingFacilities,
                        record: facility,
                      }) => {
                        handleOpenModal({
                          variant: "blocking",
                          blockFacilities: blockingFacilities,
                          currentFacility: facility,
                        });
                      },
                    },
                  },
                ],
                separator: "and",
              },
            },
          },
          {
            title: "BLOCKED BY...",
            key: "blocked_by",
            type: "string",
            properties: {
              onRender: getBlockBy,
              notSortable: true,
              composite: {
                elements: [
                  {
                    key: "blocked_by",
                    type: "button",
                    properties: {
                      onRender: getBlockByMoreCount,
                      onClick: ({
                        value: blockedFacilities,
                        record: facility,
                      }) => {
                        handleOpenModal({
                          variant: "blocked",
                          blockFacilities: blockedFacilities,
                          currentFacility: facility,
                        });
                      },
                    },
                  },
                ],
                separator: "and",
              },
            },
          },
        ]}
        actions={[
          "manage_blocked_facilities",
          "view_blocked_facilities",
          "view_blocking_facilities",
        ]}
        url={url}
      />
    </>
  );
};
