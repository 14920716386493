import { BsThreeDots } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";

import { Button } from "@/components/Button";
import { BulkActionsButtonsProps } from "./types";

import "./scss/index.scss";

export const BulkActionsButtons = <T extends Record<string, any>>({
  bulkActions,
  checkboxes,
  checkboxesHandler,
}: BulkActionsButtonsProps<T>) => {
  const { primaryAction, secondaryAction, more } = bulkActions ?? {};

  if (!checkboxes.length) return null;

  return (
    <>
      <hr className="break-line" />
      <div className="bulk-actions-container">
        <Button
          label={primaryAction?.label || "Primary Action"}
          size={"small"}
          color={"primary"}
          variant={"secondary"}
          leftIcon={MdOutlineMail}
          onClick={() => {
            primaryAction?.onClick({
              records: checkboxes,
              checkboxesHandler,
              bulkConfig: primaryAction.config,
            });
            checkboxesHandler([]);
          }}
        />
        {secondaryAction ? (
          <Button
            label={secondaryAction.label}
            size={"small"}
            color={"neutral"}
            variant={"outlined"}
            onClick={() => secondaryAction.onClick()}
          />
        ) : null}
        {more ? (
          <Button
            label="More"
            rightIcon={BsThreeDots}
            size={"small"}
            color={"neutral"}
            variant={"outlined"}
          />
        ) : null}
      </div>
    </>
  );
};
