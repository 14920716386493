import { useState } from "react";
import { debounce } from "@/utils/debounce";

export const useComment = () => {
  const [comment, setcomment] = useState("");
  const handleUpdatecomment = (value: string) => setcomment(value);
  const debounceUpdateComment = debounce(handleUpdatecomment, 500);

  return {
    comment,
    debounceUpdateComment,
  };
};
