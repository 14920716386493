import { OrderActionModal } from "../../components/common/template/orderActionModal";

export const cancelOrderAction = (recordID: number) => ({
  variant: "modal",
  title: "Cancel Order",
  modalTemplate: OrderActionModal,
  className: "destructive",
  modalProps: {
    recordID,
    header: "Cancel Order",
    saveButtonLabel: "Cancel this Order",
    url: "api:internal-orders-cancel",
    saveButtonColor: "destructive",
    backButtonLabel: "Go back",
  },
});
