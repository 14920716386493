import { useState, useEffect } from "react";

export const useAutoFocus = (inputRef: { current: Element | null }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const checkFocus = () => {
    if (inputRef.current)
      setIsFocused(document.activeElement === inputRef.current);
  };

  useEffect(() => {
    const handleFocus = () => checkFocus();
    const handleBlur = () => {
      setTimeout(() => {
        checkFocus();
      }, 200); //Delay added so onClick event is triggered
    };

    const input = inputRef.current;

    if (input) {
      if (input === document.activeElement) setIsFocused(true);

      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    }

    return () => {
      if (input) {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  return isFocused;
};
