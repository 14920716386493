import useSWR from "swr";

import { getURL } from "@/utils/getURL";
import { axiosRequest } from "@root/src/hooks/axiosRequest";
import { Todo } from "@root/src/types";

export const useGetOptions = (url: string, recordID: number) => {
  const optionsURL: string = getURL(url, {
    pk: recordID.toString(),
  });

  const { data, isLoading } = useSWR(optionsURL, (url: string) =>
    axiosRequest({ url })
  );

  const options = (data as Todo)?.data.reason;

  return isLoading ? [] : options;
};
