import { ArgsType } from "./types";
import { addClass } from "./utils/addClass";
import { evaluateCondition } from "./utils/evalueateCondition";

/**
 *  Returs a string composed by the base class and the args (if any)
 *
 * @param baseClass - The base class name
 * @param args - Any string to be added to the class
 *
 * @example
 * Simple usage:
 * ```
 * getClassName('facility')
 * ```
 * @example
 * Adding a dynamic value:
 * ```
 * getClassName('facility', color) //color(string) defined somewhere in the code
 * ```
 * @example
 * Adding a value by boolean:
 * ```
 * getClassName('facility', {
 *  'hide-facility': isActive // isActive(boolean) defined somewhere in the code
 * })
 * ```
 * @example
 * Adding a value by boolean but using a function:
 * ```
 * getClassName('facility', {
 *  'show-facility': 20 < 10
 * })
 * ```
 * @example
 * Adding a string that could be undefined:
 * getClassName('facility', {
   class
 })
 */
export const getClassName = (
  baseClass: string,
  ...args: (string | ArgsType)[]
): string => {
  let className: string = baseClass;

  args.forEach((argument) => {
    if (typeof argument === "string")
      return (className = addClass(className, argument) || className);

    Object.entries(argument).forEach(([key, condition]) => {
      if (!condition) return key;

      if (typeof condition === "string")
        return (className = addClass(className, condition) || className);

      return (
        evaluateCondition(condition) &&
        (className = addClass(className, key) || className)
      );
    });
  });

  return className.trim();
};
