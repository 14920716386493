import {
  DropdownMenuOption,
  DropdownMenuVariants,
} from "@/components/DropdownMenu/types";

export const getFilteredOptions = <Value, Variant extends DropdownMenuVariants>(
  options: DropdownMenuOption<Value, Variant>[],
  inputValue: string
) =>
  options.filter(({ title }) =>
    title.toString().toLowerCase().includes(inputValue.toLowerCase())
  );
