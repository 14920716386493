import { BiSearch } from "react-icons/bi";

import { Button } from "@/components/Button";
import { CheckBox } from "@/components/CheckBox";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { Icon } from "@/components/Icon";
import { useCodesContainerLogic } from "./utils/useCodesContainerLogic";
import { ItemsList } from "./ItemsList";
import { ItemsContainerItem, ItemsContainerProps } from "./types";
import "./scss/index.scss";

export const ItemsContainer = <
  Item extends ItemsContainerItem,
  FilterBy extends keyof Item
>({
  items = [],
  onClickButtonLabel = "Add",
  placeholder = "Search...",
  getItemLabel,
  label,
  disabled,
  filterOptions,
  filterBy,
  onClick,
}: ItemsContainerProps<Item, FilterBy>) => {
  const {
    handleOnSubmit,
    handleQueryChange,
    selectedItems,
    filterBy: currentFilterBy,
    handleAddItem,
    handleSelectItems,
    isDisabled,
    dropdownFilterOptions,
    filteredItems,
    handleSelectAllItems,
    isChecked,
    inputRef,
    handleFilterBy,
  } = useCodesContainerLogic<Item, FilterBy>({
    items,
    onClick,
    getItemLabel,
    filterOptions,
    filterBy,
  });

  return (
    <div className={`items-container ${disabled ? "disabled" : ""}`}>
      <header className="items-container__label">{label}</header>
      <div className="items-container__header">
        <DropdownSingleSelect
          className="items-container__header-filter-dropdown"
          selectedOption={currentFilterBy}
          disabled={disabled}
          options={dropdownFilterOptions}
          onClick={({ value }) => handleFilterBy(value as Item[FilterBy])}
        />
        <div className="items-container__search">
          <Icon
            size="small"
            className="items-container__search-icon"
            icon={BiSearch}
            color="neutral"
            variant="link"
            disabled={disabled}
          />
          <input
            className="items-container__search-input"
            type="text"
            onChange={handleQueryChange}
            ref={inputRef}
            disabled={disabled}
            placeholder={placeholder}
          />
        </div>
      </div>
      <CheckBox
        checked={isChecked}
        onClick={handleSelectAllItems}
        className="items-container__checkbox"
        disabled={disabled}
        id="select-all"
        label="Select All"
      />
      <ItemsList<Item, FilterBy>
        {...{
          items: filteredItems,
          getItemLabel,
          selectedItems,
          handleSelectItems,
          disabled,
          handleAddItem,
        }}
      />
      <Button
        disabled={disabled || isDisabled}
        label={onClickButtonLabel}
        size={"small"}
        variant={"filled"}
        color={"primary"}
        onClick={handleOnSubmit}
      />
    </div>
  );
};
