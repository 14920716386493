import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";

const url: string = getURL("api:internal-open-orders-fulfilled");
const tableOptionsUrl: string = getURL(
  "api:internal-open-orders-schema-options"
);

export const FulfilledOpenOrders: FC = () => {
  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      options={{
        url: tableOptionsUrl,
        filters: {
          filtersToExclude: ["has_block", "is_direct", "shared_with", "state"],
        },
      }}
      name="Fulfilled Open Orders"
      searchable
      headers={[
        { title: "#", key: "id" },
        { title: "LAST MODIFIED", key: "last_modified", type: "date" },
        { title: "BUYER", key: "buyer" },
        { title: "SELLER", key: "seller" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "LISTED FOR", key: "expire_after" },
        { title: "AUDIENCE", key: "audience" },
      ]}
      url={url}
    />
  );
};
