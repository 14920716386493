import { FacilityMailerDetails } from "../../../../types";

export const getFacilitiesByStatus = (
  records: FacilityMailerDetails[] | FacilityMailerDetails,
  filterActive: boolean = true
) => {
  if (Array.isArray(records))
    return records.filter((record) =>
      filterActive ? record.status === "Active" : record.status !== "Active"
    );

  if (filterActive ? records.status === "Active" : records.status !== "Active")
    return [records];

  return [];
};
