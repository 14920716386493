import { useState } from "react";
import { ItemsContainerItem } from "../../components/ItemsContainer/types";
import { UseDualListSelectedItems } from "./types";

export const useDualListSelectedItems = <Items extends ItemsContainerItem>({
  items,
  onChange,
  initialItems = [],
}: UseDualListSelectedItems<Items>) => {
  const [selectedItems, setSelectedItems] = useState<Items[] | null>(null);
  const currentItems = selectedItems ?? initialItems;

  onChange?.(currentItems);

  const handleSelectedCode = (itemToAdd: Items[]) =>
    setSelectedItems([...currentItems, ...itemToAdd]);

  const handleRemoveItem = (itemsToAdd: Items[]) =>
    setSelectedItems(currentItems.filter((item) => !itemsToAdd.includes(item)));

  const filteredMockItems = items.filter(
    (item) =>
      !currentItems.some((selectedItem) => selectedItem.tags === item.tags)
  );

  return {
    handleSelectedCode,
    handleRemoveItem,
    filteredMockItems,
    selectedItems,
    currentItems,
  };
};
