// import { ChangeEvent } from "react";

import { TextBoxProps } from "./types";
import { debounce } from "@/utils/debounce";

import "./scss/index.scss";

export const TextBox = ({
  message,
  label,
  onChange: handleOnChange,
  value,
}: TextBoxProps) => {
  // const onChange = (value: string) => handleOnChange(value.trim());
  const debounceOnChange = debounce(handleOnChange, 500);
  const numberOfCharacters: number = message.length;
  const maxLength: number = 1000;

  return (
    <div className="text-area-container">
      <label id="textbox" aria-labelledby="textbox">
        {label}
      </label>
      <textarea
        onChange={({ target: { value } }) => debounceOnChange(value)}
        name="textbox"
        id="textbox"
        cols={20}
        defaultValue={value}
        rows={7}
        maxLength={maxLength}
      ></textarea>
      <div className="number-of-characters">
        {numberOfCharacters} / {maxLength} characters remaining
      </div>
    </div>
  );
};
