import { FC } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { getNavbarIcon } from "./utils/getNavbarIcon";
import { TableNavbarIconProps } from "./types";

export const TableNavbarIconHelper: FC<TableNavbarIconProps> = ({
  tooltipHelper,
}) => {
  if (!tooltipHelper) return null;

  const { message, variant } = tooltipHelper;

  return (
    <div
      data-testid="table-navbar-icon-helper"
      className="table-navbar__info-container"
    >
      <a
        href="#"
        data-tooltip-content={message}
        data-tooltip-id={"navbar"}
        data-tooltip-place="top"
      >
        {getNavbarIcon[variant]()}
      </a>
      <ReactTooltip id="navbar" />
    </div>
  );
};
