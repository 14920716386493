import { useState, useRef, useLayoutEffect } from "react";

import { UseTruncateProps } from "./types";

/**
 * This hook is used to detect if an element(s) has been truncated and returns the truncated states.
 *
 * @example
 * ```
 * const { truncatedStates, registerElement } = useTruncate();
 *
 * if(truncatedStates[index]) console.log("Element is truncated");
 *
 * return (
 * <div ref={registerElement(index)}>Test element</div>)
 * )
 * ```
 *
 * @param accessChild - Whether to access the children of the element instead of the element itself
 * @returns - An object containing the truncated states and a function to register an element
 */
export const useTruncate = ({ accessChild = false }: UseTruncateProps = {}) => {
  const [truncatedStates, setTruncatedStates] = useState<
    Record<string, boolean>
  >({});

  const refs = useRef<Record<string, HTMLElement | null>>({});

  const registerElement = (index: string) => (element: HTMLElement | null) => {
    return (refs.current[index] = element);
  };

  useLayoutEffect(() => {
    for (const [key, element] of Object.entries(refs.current)) {
      if (!element) return;

      const currentElement = accessChild ? element.children[0] : element;

      if (!currentElement) continue;
      if (currentElement.scrollWidth <= currentElement.clientWidth) continue;

      setTruncatedStates((prevState) => ({
        ...prevState,
        [key]: true,
      }));
    }
  }, [accessChild]);

  const getTooltipId = (key: string) =>
    truncatedStates[key] ? "table-row-cell-tooltip" : "";

  return {
    truncatedStates,
    registerElement,
    getTooltipId,
  };
};
