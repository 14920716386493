import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { GrayFlag, RedFlag } from "./assets";
import { handleCreateOrder } from "../common/utils/handleCreateOrder";
const url: string = getURL("api:internal-orders-list");
const tableOptionsUrl: string = getURL("api:internal-orders-schema-options");

export const Orders: FC = () => (
  <Table
    id={"id"}
    name="All Orders"
    sortBy="-ordered_at"
    onAddRecord={handleCreateOrder}
    options={{ url: tableOptionsUrl }}
    headers={[
      { title: "#", key: "id", properties: { notSortable: true } },
      {
        title: "PLACED ON",
        key: "ordered_at",
        type: "date",
      },
      { title: "DELIVERED ON", key: "received_at", type: "date" },
      { title: "TYPE", key: "order_type" },
      {
        title: "ITEMS",
        properties: { notSortable: true },
        key: "component_string",
      },
      { title: "BUYER", key: "buyer" },
      { title: "SELLER", key: "seller" },
      { title: "AUDIENCE", key: "audience" },
      { title: "SHIPPER", key: "shipping_method" },
      {
        title: " ",
        key: "is_flagged",
        type: "boolean",

        properties: {
          booleanType: {
            true: <RedFlag />,
            false: <GrayFlag />,
          },
        },
      },
      {
        title: "STATUS",
        key: "order_status",
        type: "badge",

        properties: {
          badgeColor: {
            "delivered": "success",
            "ordered": "neutral",
            "canceled": "destructive",
            "in-transit": "primary",
          },
        },
      },
    ]}
    url={url}
    actions={[
      "order_activity",
      "add_order_comment",
      "progress_order",
      "edit_order",
      "flag_order",
      "cancel_order",
    ]}
  />
);
