import { useContext, useEffect, useRef, useState } from "react";

import { TableContext } from "@/components/Table/context";
import { useSearchParams } from "@/hooks/useSearchParams";
import { formatDate } from "@root/src/components/Table/api/useFetch/utils/buildFetchURL/utils/formatDate";
import { TableDateRangeOptions } from "@/components/Table/types/TableFilters";
import { getDateRangeOptions } from "../../utils/getDateRangeOptions";
import { UpdateDateRange } from "./types";
import { DateRangeOptions } from "../../types";

export const useDatePickerLogic = (
  dateFilterOptions: TableDateRangeOptions[]
) => {
  // Context & Search Params 
  const { updateSearchParams, sortBy, calendarConfig } =
    useContext(TableContext);
  const { searchParams } = useSearchParams();

  // References for external calendar controls 
  const changeMonthRef = useRef<(value: number) => void>();
  const changeYearRef = useRef<(value: number) => void>();

  // Extract query parameters 
  const startDate = searchParams.get("start_date") || "";
  const endDate = searchParams.get("end_date") || "";
  const dateField = searchParams.get("date_field") || "";

  // Manage selected date range state 
  const defaultDateRange = startDate && endDate ? "Custom Range" : "";
  const [dateRange, setDateRange] = useState<string>(defaultDateRange);

  // Update the selected date range 
  const handleUpdateSelectedDateRange = (value: string) => {
    if (dateRange === "Custom Range" && value === "Custom Range") return;
    setDateRange(value);
  };

  // Update search params when date range changes 
  const handleUpdateDateRange = ({ startDate, endDate }: UpdateDateRange) => {
    updateSearchParams?.("start_date", formatDate(startDate));
    updateSearchParams?.("end_date", formatDate(endDate));
  };

  // Default selected date field 
  const defaultDateFieldTitle = dateFilterOptions[0]?.title;
  const defaultDateFieldValue =
    sortBy && dateFilterOptions.some((option) => option.value === sortBy)
      ? sortBy
      : dateFilterOptions[0]?.value;

  // Allowed future dates from calendar config 
  const { allowedFutureDates } = calendarConfig ?? {};

  // Ensure date_field is set if not present in search params 
  useEffect(() => {
    if (!dateField && defaultDateFieldValue) {
      updateSearchParams?.("date_field", defaultDateFieldValue);
    }
  }, [dateField, defaultDateFieldValue, updateSearchParams]);

  // Predefined date range options 
  const dateRangeOptions: DateRangeOptions[] = getDateRangeOptions({
    handleUpdateSelectedDateRange,
    handleUpdateDateRange,
  });

  return {
    changeMonthRef,
    changeYearRef,
    startDate,
    endDate,
    dateRangeOptions,
    handleUpdateSelectedDateRange,
    handleUpdateDateRange,
    dateRange,
    dateFilterOptions,
    defaultDateFieldTitle,
    updateSearchParams,
    dateField,
    allowedFutureDates,
  };
};
