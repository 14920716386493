import { Button } from "@/components/Button";
import { ManageBlockedFacilitiesFooterProps } from "./types";

export const ManageBlockedFacilitiesFooter = ({
  onSubmit,
  onClose,
}: ManageBlockedFacilitiesFooterProps) => (
  <footer className="manage-blocked-facilities-modal__footer">
    <Button
      onClick={onClose}
      className="manage-blocked-facilities-modal__footer-button--cancel"
      label="Cancel"
      color="neutral"
      variant="outlined"
    />
    <Button
      className="manage-blocked-facilities-modal__footer-button--save"
      label="Save Changes"
      onClick={onSubmit}
      color="primary"
      variant="filled"
    />
  </footer>
);
