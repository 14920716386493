import { useEffect } from "react";

export const useDelayedTableLoading = (
  isLoading: boolean,
  setIsTableLoading?: (value: boolean) => void
) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const wait: number = 2000;

    // Start a timer; if still loading after 3s, show loader
    if (isLoading)
      timeoutId = setTimeout(() => setIsTableLoading?.(true), wait);

    // If loading finished or didn't start, ensure loader is hidden
    setIsTableLoading?.(false);

    return () => clearTimeout(timeoutId);
  }, [isLoading]);
};
