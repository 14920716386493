import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { BlockIcon } from "../assets";

const url: string = getURL("api:internal-open-orders-active");
const tableOptionsUrl: string = getURL(
  "api:internal-open-orders-schema-options"
);

export const ActiveOpenOrders: FC = () => {
  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      name="Active Open Orders"
      searchable
      headers={[
        { title: "#", key: "id" },
        { title: "LAST MODIFIED", key: "last_modified", type: "date" },
        { title: "BUYER", key: "buyer" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "LISTED FOR", key: "expire_after" },
        { title: "AUDIENCE", key: "audience" },
        {
          title: "BLOCKED",
          key: "has_block",
          type: "boolean",

          properties: {
            booleanType: {
              true: <BlockIcon />,
            },
          },
        },
      ]}
      options={{
        url: tableOptionsUrl,
        filters: {
          filtersToExclude: ["seller", "is_direct", "shared_with", "state"],
        },
      }}
      url={url}
    />
  );
};
