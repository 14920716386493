import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";

const url: string = getURL("api:internal-packages-purchased");
const tableOptionsUrl: string = getURL("api:internal-packages-schema-options");

export const PurchasedPackages: FC = () => (
  <Table
    id={"id"}
    sortBy="-last_modified"
    name="Purchased Packages"
    options={{
      url: tableOptionsUrl,
      filters: {
        filtersToExclude: [
          "has_block",
          "is_private",
          "shared_with",
          "pkg_status",
        ],
      },
    }}
    searchable
    headers={[
      { title: "#", key: "id" },
      { title: "MODIFIED", key: "last_modified", type: "date" },
      { title: "SELLER", key: "seller" },
      { title: "BUYER", key: "buyer" },
      {
        title: "ITEMS",
        properties: { notSortable: true },
        key: "component_string",
      },
      { title: "LISTED FOR", key: "listed_for" },
      { title: "AUDIENCE", key: "audience" },
    ]}
    url={url}
  />
);
