import { FC, useContext } from "react";
import { motion } from "motion/react";

import { TableHeaderItemProps } from "./types";
import { TableContext } from "@/components/Table/context";
import { updateTableSortBy } from "./utils/updateTableSortBy";
import { TableHeaderItemIcon } from "./components/TableHeaderItemIcon";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useTruncate } from "@/components/Table/hooks/useTruncate";
import { GENERIC_FADE_ANIMATION } from "@/constants";

import "./scss/index.scss";

export const TableHeaderItem: FC<TableHeaderItemProps> = ({
  header,
  resetExpandState,
}) => {
  const { searchParams } = useSearchParams();
  const { registerElement, getTooltipId } = useTruncate();
  const {
    updateSearchParams,
    sortBy: defaultSortBy,
    expandableRow,
  } = useContext(TableContext);
  const { title, key, properties } = header;
  const headerKey: string = Array.isArray(key) ? key[0] : key;
  const sortBy: string | null | undefined =
    searchParams.get("sort_by") || defaultSortBy;
  const { notSortable } = properties ?? {};

  return (
    <motion.th
      layout
      key={title}
      className={`table-header ${notSortable ? "not-sortable" : ""}`}
      data-testid="table-header-container"
      onClick={() =>
        !notSortable &&
        updateTableSortBy({
          updateSearchParams,
          headerKey,
          resetExpandState,
          sortBy,
          expandableRow,
        })
      }
    >
      <div className="table-header-content">
        <motion.span
          {...GENERIC_FADE_ANIMATION}
          data-tooltip-content={title}
          data-tooltip-id={getTooltipId(headerKey)}
          ref={registerElement(headerKey)}
          className="header-title"
        >
          {title}
        </motion.span>
        <TableHeaderItemIcon
          {...{
            headerKey,
            sortBy,
          }}
        />
      </div>
    </motion.th>
  );
};
