import { useCallback, useMemo, useState } from "react";

import { getFilteredOptions } from "./utils/getFilteredOptions";
import { debounce } from "@/utils/debounce";
import { getDropdownTitle } from "@/components/utils/getDropdownTitle";
import { DropDownSingleSelectSearchableProps } from "../../types";
import {
  DropdownMenuOption,
  DropdownMenuSelectedOption,
  DropdownMenuVariants,
} from "@/components/DropdownMenu/types";
import { DropdownMenuItemProps } from "@/components/DropdownMenu/utils/getDropdownMenuVariant/components/DropdownMenuItem/types";
import { useOutsideClick } from "@/hooks/useOutsideClick";

export const useDropdownSingleSelectSearchableLogic = <
  Value,
  Variant extends DropdownMenuVariants,
>({
  options,
  placeholder,
  selectedOption,
  onClick,
  loading,
}: Pick<
  DropDownSingleSelectSearchableProps<Value, Variant>,
  "placeholder" | "selectedOption" | "onClick" | "loading" | "options"
>) => {
  // State
  const [filteredOptions, setFilteredOptions] = useState<
    DropdownMenuOption<Value, Variant>[]
  >(() => enhanceOptions(options));

  // Outside Click Detection
  const { ref, clickedOutside, clickedOutsideHandler } =
    useOutsideClick<HTMLInputElement>();

  // Derived Variables
  const thereAreNoOptions = !options.length;
  const title = getDropdownTitle({
    loading,
    placeholder,
    thereAreNoOptions,
    selectedOption,
  });

  // Memoized Enhanced Options
  const enhancedOptions = useMemo(() => enhanceOptions(options), [options]);

  // Handlers
  const resetInputAndOptions = useCallback(() => {
    if (ref.current) {
      ref.current.value = "";
    }
    setFilteredOptions(enhancedOptions);
  }, [enhancedOptions]);

  const resetDropdownContent = useCallback(() => {
    resetInputAndOptions();
    ref.current?.focus();
    setTimeout(() => clickedOutsideHandler(true), 0); //Small delay to avoid flickering
  }, [resetInputAndOptions, clickedOutsideHandler]);

  const enhancedOnClick = useCallback(
    (prop: DropdownMenuSelectedOption<Value>) => {
      onClick?.(prop);
      resetInputAndOptions();
    },
    [onClick, resetInputAndOptions]
  );

  const debouncedAction = useCallback(
    debounce((value: string) => {
      const result = getFilteredOptions<Value, Variant>(enhancedOptions, value);
      setFilteredOptions(result);
    }, 500),
    [enhancedOptions]
  );

  const onFocus = () => clickedOutsideHandler(true);

  const handleFocusInput = () => {
    ref.current.focus();
    clickedOutsideHandler(true);
  };

  const onChange = useCallback(
    (value: string) => debouncedAction(value),
    [debouncedAction]
  );

  // Helper Functions
  function enhanceOptions(options: DropdownMenuOption<Value, Variant>[]) {
    return options.map((option) => {
      const optionWithClick = option as DropdownMenuItemProps<Value>;
      return {
        ...option,
        ...(optionWithClick?.onClick && {
          onClick: (props: DropdownMenuSelectedOption<Value>): void => {
            optionWithClick.onClick?.(props);
            resetInputAndOptions();
          },
        }),
      };
    });
  }

  // Return Values
  return {
    ref,
    clickedOutside,
    filteredOptions,
    title,
    resetDropdownContent,
    handleFocusInput,
    onFocus,
    enhancedOnClick,
    thereAreNoOptions,
    onChange,
  };
};
