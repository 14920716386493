import { getURL } from "@/utils/getURL";
import { axiosRequest } from "@/hooks/axiosRequest";
import { createToastNotifier } from "@root/src/components/utils/createToastNotifier";

export const createPackageNotification = async (packageID: string) => {
  const toastUpdate = createToastNotifier("Package notification");

  const url = getURL("api:internal-packages-send-notifications", {
    pk: packageID,
  });

  try {
    const {
      status,
      data: { message, title },
    } = await axiosRequest({ url });

    toastUpdate({ status, message: message || title });
  } catch (error) {
    console.error(error);
    toastUpdate({
      status: 500,
      message: "Unknown error, please try again later",
    });
  }
};
