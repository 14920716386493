import {
  DEFAULT_PAGINATION_CURRENT_PAGE,
  DEFAULT_PAGINATION_PAGE_SIZE,
} from "@/components/Table/constants";

export const getPaginationLogic = ({
  updateSearchParams,
  checkboxes,
  searchParams,
  checkboxesHandler,
}: any) => {
  const currentPage: number =
    Number(searchParams.get("page")) || DEFAULT_PAGINATION_CURRENT_PAGE;
  const pageSize =
    Number(searchParams.get("page_size")) || DEFAULT_PAGINATION_PAGE_SIZE;

  const onPageChange = (value: number) => {
    updateSearchParams("page", value.toString());
    //Reset BulkSelection
    checkboxes.length && checkboxesHandler([]);
  };

  const pageSizeHandler = (value: number) => {
    updateSearchParams("page_size", value.toString());
    //Reset Current Page
    updateSearchParams("page", "1");
    //Reset BulkSelection
    checkboxes.length && checkboxesHandler([]);
  };

  const siblingCount: number = 1;

  return {
    currentPage,
    pageSize,
    onPageChange,
    pageSizeHandler,
    siblingCount,
  };
};
