import { toast } from "react-toastify";

import { DownloadTableDataProps } from "./types";
import {
  DOWNLOAD_ERROR_STATUS,
  STATUS_500_ERROR_MESSAGE,
  TOAST_PROPS,
  USER_FRIENDLY_GENERIC_ERROR_MESSAGE,
  STATUS_204_WARNING_MESSAGE,
} from "./constants";
import { axiosRequest } from "@/hooks/axiosRequest";

export const downloadTableData = async ({
  exportParamName,
  exportFormat,
  currentApiEndpoint,
  keys,
}: DownloadTableDataProps) => {
  const url: string = `${currentApiEndpoint}?${keys}&${exportParamName}=${exportFormat}`;

  try {
    // Use Axios to fetch the file data as a Blob
    const { data, headers } = await axiosRequest({
      url,
      responseType: "blob", // Ensure Axios parses the response as a Blob
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    // Get the filename from the Content-Disposition header
    const disposition = headers["content-disposition"];
    let fileName = "downloaded-file.csv"; // Default fallback filename

    if (disposition && disposition.includes("filename")) {
      const fileNameMatch = disposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      );
      if (fileNameMatch?.[1]) {
        fileName = fileNameMatch[1].replace(/['"]/g, "");
      }
    }

    // Create a link element, use it to download the blob
    const link = document.createElement("a");
    const blobUrl = window.URL.createObjectURL(data);
    link.href = blobUrl;
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (error: any) {
    const { status, data } = error;

    if (status === 204)
      return toast.warning(STATUS_204_WARNING_MESSAGE, TOAST_PROPS);

    if (DOWNLOAD_ERROR_STATUS.includes(status)) {
      const errorJson = await data.text();
      const errorMessage = JSON.parse(errorJson).detail;
      return toast.error(
        errorMessage || USER_FRIENDLY_GENERIC_ERROR_MESSAGE,
        TOAST_PROPS
      );
    }

    if (status > 200 && status < 500)
      return toast.warning(USER_FRIENDLY_GENERIC_ERROR_MESSAGE, TOAST_PROPS);

    if (status >= 500) throw new Error(STATUS_500_ERROR_MESSAGE);

    toast.error(
      error?.response?.data?.detail ||
        error.message ||
        USER_FRIENDLY_GENERIC_ERROR_MESSAGE,
      TOAST_PROPS
    );
  }
};
