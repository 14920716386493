import { useState } from "react";

export const useCheckbox = <T extends Record<string, any>>(
  records?: T[] | null
) => {
  const [checkboxes, setCheckboxes] = useState<T[]>([]);

  const toggleCheckbox = (newRecord: T, id: string) => {
    const isNewRecordAlreadyInList: boolean = checkboxes.some(
      (record: T) => record[id] === newRecord[id]
    );
    if (!isNewRecordAlreadyInList)
      return setCheckboxes((array: T[]) => [...array, newRecord]);

    const newArray: T[] = checkboxes.filter(
      (record: T) => record[id] !== newRecord[id]
    );
    setCheckboxes(newArray);
  };

  const toggleAllCheckboxes = (isChecked: boolean) => {
    if (!records) return;
    if (!isChecked) return setCheckboxes([]);
    setCheckboxes(records);
  };

  const checkboxesHandler = (value: T[]) => setCheckboxes(value);

  const areAllCheckboxesSelected: boolean =
    records?.length === checkboxes.length;

  return {
    checkboxes,
    toggleAllCheckboxes,
    toggleCheckbox,
    checkboxesHandler,
    areAllCheckboxesSelected,
  };
};
