import { MutableRefObject, useRef, useState } from "react";

import { EmailInputProps } from "./types";
import { EmailsContainer } from "./components/EmailsContainer";
import { useInputValue } from "./hooks/useInputValue";
import { MailerSuggestions } from "./components/MailerSuggestions";
import { MailerRecipientsMap, RecipientKeys } from "../../types";
import { getClassName } from "@/utils/getClassName";
import { useGetMailer } from "./components/EmailsContainer/api/useGetMailer";
import { useAutoFocus } from "./components/MailerSuggestions/hooks/useAutoFocus";
import { useIsOpen } from "./hooks/useIsOpen";

import "./scss/index.scss";

export const MailerInput = <T extends RecipientKeys>({
  label,
  url,
  onChange,
  onFilter,
  initialValues = [],
  recipientDetails,
  helperText,
  autoFocus,
  onError,
}: EmailInputProps<T>) => {
  const inputRef: MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement | null>(null);
  const { inputValue, resetInputValue, handleInputValue } = useInputValue();

  const [activeSuggestions, setActiveSuggestions] =
    useState<MailerRecipientsMap[T][]>(initialValues);

  const handleAddActiveSuggestions = (record: MailerRecipientsMap[T]) =>
    setActiveSuggestions((prevData) => {
      const newSuggestions = [...prevData, record];
      onChange(newSuggestions);
      return newSuggestions;
    });

  const handleRemoveActiveSuggestions = (record: MailerRecipientsMap[T]) =>
    setActiveSuggestions((prevData) => {
      const newSuggestions = recipientDetails.removeRecipient(record, prevData);
      onChange(newSuggestions);
      return newSuggestions;
    });

  const fetchedSuggestions = useGetMailer<T>({
    inputValue,
    url,
    records: activeSuggestions,
    getSuggestionsFilter: recipientDetails.getSuggestionsFilter,
  });

  const suggestions = onFilter
    ? onFilter(fetchedSuggestions)
    : fetchedSuggestions;

  const errorMessage: string | undefined = onError?.(activeSuggestions);
  const noteMessage = errorMessage || helperText;

  const helperClassName = getClassName("note-message", {
    "error": !!errorMessage,
  });
  const emailInputContainerClassName = getClassName("email-input-container", {
    "error": !!errorMessage,
  });

  const isFocused = useAutoFocus(inputRef);
  const {
    containerRef,
    isOpen,
    handleOpen: handleOpenSuggestions,
  } = useIsOpen();

  return (
    <div
      ref={containerRef}
      onClick={handleOpenSuggestions}
      className={emailInputContainerClassName}
    >
      <label>{label}</label>
      <EmailsContainer<T>
        {...{
          inputRef,
          onChange: handleInputValue,
          errorMessage,
          autoFocus,
          onRemoveRecord: handleRemoveActiveSuggestions,
          recipientDetails,
          activeSuggestions,
        }}
      />

      {isOpen || isFocused ? (
        <MailerSuggestions<T>
          {...{
            inputRef,
            suggestions,
            onAddRecord: handleAddActiveSuggestions,
            resetInputValue,
            detailsToRender: recipientDetails.component,
          }}
        />
      ) : null}

      {noteMessage ? (
        <span className={helperClassName}>{noteMessage}</span>
      ) : null}
    </div>
  );
};
