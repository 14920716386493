import { format as dateFormat } from "date-fns";

import { RenderDateType } from "./types";

export const renderDate = ({ value, properties }: RenderDateType) => {
  const { className: getClassName, format } = properties ?? {};
  const defaultFormat: string = "P";
  const className =
    typeof getClassName === "string"
      ? getClassName
      : getClassName?.(value) || "";

  if (!value) return null;

  return (
    <div className={className}>
      {dateFormat(value, format || defaultFormat)}
    </div>
  );
};
