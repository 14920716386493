import { useContext } from "react";

import { CheckBox } from "@/components/CheckBox";
import { TableContext } from "@/components/Table/context";
import { TableRowBulkActionProps } from "./types";

export const TableRowBulkAction = ({
  record,
  idKey,
}: TableRowBulkActionProps) => {
  const { toggleCheckbox, checkboxes, bulkActions } = useContext(TableContext);

  if (!bulkActions) return null;

  const isActive: boolean =
    checkboxes?.some(
      (checkboxRecord: Record<string, unknown>) =>
        checkboxRecord[idKey] === record[idKey]
    ) || false;

  return (
    <td>
      <CheckBox
        onClick={() => toggleCheckbox?.(record, idKey)}
        checked={isActive}
      />
    </td>
  );
};
