import { ButtonHeader } from "@/components/Table/types/TableHeaders";
import "./scss/index.scss";

export const RenderButton = ({
  value,
  properties,
  record,
}: Pick<ButtonHeader, "properties"> & {
  value: string;
  record: Record<string, unknown>;
}) => {
  const {
    className: getClassName,
    onRender,
    onClick: handleOnClick,
  } = properties ?? {};

  const cellValue = onRender ? onRender(value) : value;
  if (!cellValue) return null;

  const className =
    typeof getClassName === "string"
      ? getClassName
      : getClassName?.(value) || "";

  return (
    <button
      onClick={() => handleOnClick?.({ value, record })}
      className={`table-row__button ${className}`}
    >
      {cellValue}
    </button>
  );
};
