import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { FacilityUserType } from "./types/facilityUser";
import { AffiliateFacilityUserType } from "./types/affiliateFacilityUser";
import { TableHeaders } from "@/components/Table/types/TableHeaders";
import { Badge } from "@/components/Badge";
import { UserDetails } from "@/components/Table/templates/UserDetails";

export const FacilityUsers: FC = () => {
  const headers: TableHeaders = [
    {
      title: "NAME",
      key: "full_name",
      properties: {
        composite: {
          elements: [
            {
              key: "is_primary_contact",
              type: "boolean",
              properties: {
                booleanType: {
                  true: (
                    <Badge
                      label={"PRI"}
                      size={"small"}
                      color={"neutral"}
                      variant={"filled"}
                    />
                  ),
                },
              },
            },
          ],
        },
      },
    },
    { title: "EMAIL", key: "email" },
    { title: "FACILITY", key: ["facility", "name"] },
    {
      title: "ROLE",
      key: "role",
      type: "badge",
      properties: {
        badgeColor: {
          "group-admin": "success",
          "tech": "neutral",
          "admin": "neutral",
        },
      },
    },
  ];
  const facilityURL: string = getURL("api:facility-users-list", {
    parent_lookup_pk: "current",
  });
  const affiliateURL: string = getURL("api:affiliate-users-list", {
    parent_lookup_pk: "current",
  });

  return (
    <>
      <Table<FacilityUserType>
        id={"user_id"}
        name="Users"
        searchable
        tooltipHelper={{
          variant: "?",
          message: "A list of all users in your facility.",
        }}
        expandableRowContent={{
          component: UserDetails,
          props: {
            expandableRow: {
              id: "user_id",
              primaryMessage: "${full_name}",
              secondaryMessage: "${role} at ${facility.name}.",
              options: {
                Email: "email",
                Phone: "formatted_office_number",
                Mobile: "formatted_cell_number",
              },
            },
          },
        }}
        headers={headers}
        url={facilityURL}
      />
      <hr style={{ margin: 0 }} />
      <Table<AffiliateFacilityUserType>
        id={"user_id"}
        name="Affiliate Users"
        searchable
        tooltipHelper={{
          variant: "?",
          message:
            "A list of users outside of your facility that you have access to.",
        }}
        headers={headers}
        expandableRowContent={{
          component: UserDetails,
          props: {
            expandableRow: {
              id: "user_id",
              primaryMessage: "${full_name}",
              secondaryMessage: "${role} at ${facility.name}.",
              options: {
                Email: "email",
                Phone: "formatted_office_number",
                Mobile: "formatted_cell_number",
              },
            },
          },
        }}
        url={affiliateURL}
      />
    </>
  );
};
