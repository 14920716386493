import { useContext, useState } from "react";

import { useOutsideClick } from "@/hooks/useOutsideClick";
import { FiltersType } from "@/components/Table/types/TableFilters";
import { TableFilterOption } from "@/components/Table/types";
import { TableContext } from "../../../../context";

export const useFiltersContainerLogic = (
  tableFilterOptions: TableFilterOption[]
) => {
  const dataDefaultValues = {
    label: "",
    key: "",
    title: "",
    value: "",
  };
  const [data, setData] = useState<FiltersType>(dataDefaultValues);

  const { getSearchParams } = useContext(TableContext);
  const { addFilter } = getSearchParams;

  const resetData = () => {
    setData(dataDefaultValues);
  };

  const dataHandler = (value: FiltersType) =>
    setData((prev) => ({ ...prev, ...value }));

  const topFilterOptions = tableFilterOptions.map(({ label, key }) => ({
    title: label,
    value: key,
  }));

  //Values for teh secondary dropdown
  const subHeaderOptions = tableFilterOptions.find(
    (filter) => filter.key === data.key
  )?.values;

  const { clickedOutside, clickedOutsideToggle, ref } = useOutsideClick({
    onClickOutsideAction: resetData,
  });

  const toggleOpenFilter = () => clickedOutsideToggle();

  const oncloseModal = () => {
    toggleOpenFilter();
    resetData();
  };

  const submitFilters = () => {
    addFilter({
      key: data.key as string,
      value: data.value as string,
    });
    oncloseModal();
  };

  return {
    data,
    submitFilters,
    oncloseModal,
    subHeaderOptions: subHeaderOptions ?? [],
    ref,
    topFilterOptions,
    toggleOpenFilter,
    clickedOutside,
    dataHandler,
  };
};
