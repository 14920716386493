import { OrderTextAreaProps } from "./types";

export const OrderTextArea = ({
  isMultiReason,
  handleSelectedOption,
}: OrderTextAreaProps) => (
  <div className="orders-actions-modal-notes-container">
    <label
      htmlFor="order-action-notes"
      className="orders-actions-modal-notes-label"
    >
      Notes ({isMultiReason ? "required" : "optional"})
    </label>
    <textarea
      className="orders-actions-modal-notes-textarea"
      name="order-action-notes"
      id="order-action-notes"
      rows={5}
      onChange={({ target: { value } }) => handleSelectedOption("notes", value)}
      placeholder="Please note any details about this order so we can follow-up."
    />
  </div>
);
