import { MailerModal } from "../../components/Email/template/MailerModal";

export const emailAction = (record: object, config: object | undefined) => ({
  variant: "modal",
  title: "Email",
  modalTemplate: MailerModal,
  modalProps: {
    config,
    records: record,
  },
});
