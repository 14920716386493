import { DEFAULT_DROPDOWN_HEADER } from "../../constants";
import { getLast30Days } from "./utils/getLast30Days";
import { getLast3Months } from "./utils/getLast3Months";
import { getLast7Days } from "./utils/getLast7days";
import { getLast90Days } from "./utils/getLast90Days";
import { getLastMonth } from "./utils/getLastMonth";
import { GetDateRangeOptionsProps } from "./types";
import { DateRangeOptions } from "../../types";

export const getDateRangeOptions: (
  dateRange: GetDateRangeOptionsProps
) => DateRangeOptions[] = ({
  handleUpdateSelectedDateRange,
  handleUpdateDateRange,
}: GetDateRangeOptionsProps) => [
  ["Last 7 days", getLast7Days],
  ["Last 30 days", getLast30Days],
  ["Last month", getLastMonth],
  ["Last 90 days", getLast90Days],
  ["Last 3 months", getLast3Months],
  ["Custom Range", () => handleUpdateSelectedDateRange("Custom Range")],
  [
    "All time",
    () => {
      handleUpdateSelectedDateRange(DEFAULT_DROPDOWN_HEADER);
      handleUpdateDateRange({ startDate: null, endDate: null });
    },
  ],
];
