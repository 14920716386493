import { TbChevronDown, TbChevronRight } from "react-icons/tb";

import { TableRowIconProps } from "./types";

import "./scss/index.scss";

export const TableRowIcon = ({
  expandState,
  recordId,
}: TableRowIconProps): JSX.Element => {
  const isActive = expandState?.[recordId];

  return isActive ? (
    <TbChevronDown className="table-row__icon" data-testid="down-arrow" />
  ) : (
    <TbChevronRight className="table-row__icon" data-testid="right-arrow" />
  );
};
