import { GetActionProps } from "./types";
import { TableAction } from "@/components/Table/types/TableActions";
import { editFacilityAction } from "./editFacilityAction";
import { orderActivityAction } from "./actions/orderActivityAction";
import { cancelOrderAction } from "./actions/cancelOrderAction";
import { editOrderAction } from "./actions/editOrderAction";
import { editStandingOrderAction } from "./actions/editStandingOrderAction";
import { packageNotificationAction } from "./actions/packageNotificationAction";
import { editUserPermissionsAction } from "./orders/editUserPermissionsAction";
import { emailAction } from "./orders/emailAction";
import { flagOrderAction } from "./orders/flagOrderAction";
import { impersonateAction } from "./orders/impersonateAction";
import { progressOrderAction } from "./orders/progressOrderAction";
import { standingOrderActivityAction } from "./orders/standingOrderActivityAction";
import { submitDispositionForm } from "./orders/submitDispositionForm";
import { submitRecallAction } from "./orders/submitRecallAction";
import { viewProfileAction } from "./orders/viewProfileAction";
import { DropdownMenuOption } from "@/components/DropdownMenu/types";
import { addOrderComment } from "./actions/addOrderComment";
import { manageBlockedFacilities } from "./actions/manageBlockedFacilities";
import { blockedList, blockingList } from "./actions/blockList";

export const getAction = <T extends Record<string, any>>({
  action,
  record,
  config,
  id = "id",
}: GetActionProps<T>): DropdownMenuOption => {
  const recordID: number = record[id || "id"];

  const actionsMap: Record<TableAction, object> = {
    order_activity: orderActivityAction(recordID),
    submit_recall: submitRecallAction(recordID),
    submit_disposition_form: submitDispositionForm(recordID),
    view_profile: viewProfileAction(recordID),
    edit_facility: editFacilityAction(recordID),
    impersonate: impersonateAction(recordID),
    edit_user_permissions: editUserPermissionsAction(record),
    flag_order: flagOrderAction(recordID),
    email: emailAction(record, config),
    standing_order_activity: standingOrderActivityAction(recordID),
    progress_order: progressOrderAction(recordID),
    edit_order: editOrderAction(recordID),
    edit_standing_order: editStandingOrderAction(recordID),
    package_notification: packageNotificationAction(recordID),
    cancel_order: cancelOrderAction(recordID),
    add_order_comment: addOrderComment(recordID),
    manage_blocked_facilities: manageBlockedFacilities(record),
    view_blocked_facilities: blockedList({
      title: "Facilities blocked by this facility",
      facility: record,
    }),
    view_blocking_facilities: blockingList({
      title: "Facilities blocking this facility",
      facility: record,
    }),
  };

  return actionsMap[action] as DropdownMenuOption;
};
