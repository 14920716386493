import { ItemsContainerItem } from "../../../../types";
import { HandleSubmitProps } from "./types";

export const handleSubmit =
  <Item extends ItemsContainerItem>({
    onClick,
    selectedItems,
    resetStates,
  }: HandleSubmitProps<Item>) =>
  () => {
    onClick?.(selectedItems);
    resetStates();
  };
