import { getURL } from "@/utils/getURL";
import { Serializer, updateUserPermissionsProps } from "./types";
import { getGroupsSerializer } from "./getGroupsSerializer";
import { axiosRequest } from "@/hooks/axiosRequest";
import { createToastNotifier } from "@root/src/components/utils/createToastNotifier";

export const updateUserPermissions = async <T extends Record<string, any>>({
  user_id,
  permissions,
  onCloseModal,
  mutateTable,
}: updateUserPermissionsProps<T>) => {
  const requestBody: Serializer = {
    user_id: +user_id,
    groups: getGroupsSerializer(permissions),
  };

  onCloseModal();

  const url: string = getURL("api:internal-users-detail", { pk: user_id });
  const toastUpdate = createToastNotifier("update-user-permissions");

  try {
    const {
      status,
      data: { detail },
    } = await axiosRequest({
      url,
      method: "patch",
      data: requestBody,
    });
    toastUpdate({
      status,
      message: detail || "Permissions updated successfully",
    });
    mutateTable?.();
  } catch (error: any) {
    toastUpdate({
      status: 500,
      message: error.detail || "Permissions update failed, please try again",
    });
  }
};
