import { getURL } from "@/utils/getURL";
import { SendEmailProps } from "./types";
import { axiosRequest } from "@/hooks/axiosRequest";
import { createToastNotifier } from "@root/src/components/utils/createToastNotifier";

export const sendEmail = async ({ onCloseModal, data }: SendEmailProps) => {
  const url: string = getURL("api:custom-email-message-list");
  onCloseModal();
  const updateToast = createToastNotifier("send-email");

  try {
    const {
      status,
      data: { detail },
    } = await axiosRequest({
      url,
      method: "post",
      data,
    });

    updateToast({
      status,
      message: detail || "Email sent successfully",
    });
  } catch (error: any) {
    updateToast({
      status: 500,
      message: error.detail || "Failed to send email",
    });
  }
};
