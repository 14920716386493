import { OnClickProps } from "./types";

export const updateTableSortBy = ({
  headerKey,
  updateSearchParams,
  sortBy,
  resetExpandState,
  expandableRow,
}: OnClickProps) => {
  expandableRow && resetExpandState?.();

  if (sortBy === headerKey) {
    return sortBy[0] === "-"
      ? updateSearchParams?.("sort_by", headerKey)
      : updateSearchParams?.("sort_by", `-${headerKey}`);
  }

  updateSearchParams?.("sort_by", headerKey);
};
