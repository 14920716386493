import { useInternalFacilitiesTags } from "../internalFacilitiesTags";
import { InternalFacilitiesTags } from "../internalFacilitiesTags/types";
import { ManageBlockedFacilitiesModalProps } from "../../types/index";

export const useManageBlockedFacilitiesModalLogic = ({
  facility,
}: ManageBlockedFacilitiesModalProps) => {
  const { is_blocking } = facility;
  const internalFacilitiesTags = useInternalFacilitiesTags();

  // Get tags from facilities
  const tags = internalFacilitiesTags
    .map((facility) => facility.tags)
    .reduce((acc, tags) => acc.concat(tags), []);
  // Remove duplicates
  const uniqueTags: string[] = [...new Set(tags)];

  // Get blocking facilities objects and filter out undefined
  const blockingFacilities = is_blocking
    .map(({ id }) =>
      internalFacilitiesTags.find((facility) => facility.value === id)
    )
    .filter(Boolean) as InternalFacilitiesTags[];

  const internalFacilitiesExcludingCurrent = internalFacilitiesTags.filter(
    (item) => item.value !== facility.id
  );

  return {
    internalFacilitiesTags: internalFacilitiesExcludingCurrent,
    blockingFacilities,
    uniqueTags,
  };
};
