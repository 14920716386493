import { useEffect } from "react";

import { UseDefaultSortByProps } from "./types";

export const useDefaultSortBy = ({
  defaultSortBy,
  searchParams,
  updateSearchParams,
}: UseDefaultSortByProps) => {
  useEffect(() => {
    const sortBy: string = searchParams.get("sort_by") || defaultSortBy;
    !searchParams.has("sort_by") && updateSearchParams("sort_by", sortBy);
  }, []);
};
