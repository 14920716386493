import { TableCollapseRowProps } from "@/components/Table/types/TableCollapseRow";
import { replacePlaceholders } from "./utils/replacePlaceholders";
import { transformOptions } from "./utils/transformOptions";

export const collapseRowOptions = (
  record: Record<string, any>,
  expandableRow: TableCollapseRowProps
): TableCollapseRowProps => {
  const { id, primaryMessage, secondaryMessage, options } = expandableRow;

  const recordID: string = record[id];

  return {
    id: recordID,
    primaryMessage: replacePlaceholders(primaryMessage, record),
    secondaryMessage: secondaryMessage
      ? replacePlaceholders(secondaryMessage, record)
      : undefined,
    options: transformOptions(options, record),
  };
};
