import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { handleCreateStandingOrder } from "../common/utils/handleCreateStandigOrder";

const url: string = getURL("api:internal-standing-orders-inactive");
const tableOptionsUrl: string = getURL(
  "api:internal-standing-orders-schema-options"
);

export const InactiveStandingOrders: FC = () => (
  <Table
    id={"facility_id"}
    onAddRecord={handleCreateStandingOrder}
    options={{
      url: tableOptionsUrl,
      filters: { filtersToExclude: ["final_order_date"] },
    }}
    sortBy="next_order"
    name="Inactive Standing Orders"
    calendarConfig={{
      allowedFutureDates: ["next_order"],
    }}
    searchable
    headers={[
      { title: "#", key: "id" },
      {
        title: "ITEMS",
        properties: { notSortable: true },
        key: "component_string",
      },
      { title: "BUYER", key: "buyer" },
      { title: "SELLER", key: "seller" },
      { title: "REPEATS", key: "frequency" },
      { title: "DAY", key: "day" },
      { title: "NEXT ORDER", key: "next_order", type: "date" },
      {
        title: "STATUS",
        key: "state",
        type: "badge",
        properties: {
          badgeColor: {
            "running": "success",
            "paused": "neutral",
            "canceled": "destructive",
            "expired": "destructive",
          },
        },
      },
    ]}
    url={url}
    actions={["standing_order_activity", "edit_standing_order"]}
  />
);
