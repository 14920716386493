import { useState } from "react";

import { useFetch } from "../../api/useFetch";
import { useCheckbox } from "./hooks/useCheckbox";
import { UseTableLogicProps } from "./types";
import { useExpandState } from "./hooks/useExpandState";
import { useSearchParams } from "@/hooks/useSearchParams";
import { buildFetchUrl } from "../../api/useFetch/utils/buildFetchURL";
import { getPaginationLogic } from "./utils/getPaginationLogic";
import { useDefaultSortBy } from "./hooks/useDefaultSortBy";
import { useTableOptions } from "./hooks/useTableOptions";

export const useTableLogic = <T extends Record<string, any>>({
  url,
  headers,
  sortBy,
  options,
}: UseTableLogicProps) => {
  const firstHeaderKey = Array.isArray(headers[0].key)
    ? headers[0].key.at(-1)!
    : headers[0].key;
  const defaultSortBy: string = sortBy || firstHeaderKey;

  //Custom hook for handling the search params
  const getSearchParams = useSearchParams<T>(mutationForSearchParam);
  //Table options
  const tableOptions = useTableOptions(options);

  //Set the default sort by after loading the table
  useDefaultSortBy({
    defaultSortBy,
    searchParams: getSearchParams.searchParams,
    updateSearchParams: getSearchParams.updateSearchParams,
  });

  //Logic used for the dropdown tabs inside the table
  const { expandState, expandStateHandler, resetExpandState } =
    useExpandState();

  //Build the fetch url
  const fetchUrl: string = buildFetchUrl({
    url,
    searchParams: getSearchParams.searchParams,
    defaultSortBy,
  });

  const [isTableLoading, setIsTableLoading] = useState(false);

  const {
    data,
    mutate: mutateTable,
    isValidating,
    isRefreshing,
  } = useFetch<T>({
    url: fetchUrl,
    setIsTableLoading,
  });

  const records = data?.results || [];

  //Function for mutating the table
  function mutationForSearchParam() {
    return mutateTable ? mutateTable() : Promise.resolve(undefined);
  }

  const checkboxesConfig = useCheckbox(records);
  const pagination = getPaginationLogic({
    ...getSearchParams,
    checkboxes: checkboxesConfig.checkboxes,
    checkboxesHandler: checkboxesConfig.checkboxesHandler,
  });

  return {
    checkboxesConfig,
    tableOptions,
    data: {
      error: data?.error,
      allRecordsCount: data?.count,
      records,
      isLoading: isTableLoading,
      isValidating,
      isRefreshing,
    },
    getSearchParams,
    mutateTable,
    expandState: {
      expandState,
      expandStateHandler,
      resetExpandState,
    },
    pagination,
  };
};
