import { useEffect } from "react";
import { TypeOptions, toast } from "react-toastify";

import { getDjangoMessages } from "@root/src/utils/getDjangoMessages";
import { TOAST_CONFIG } from "@root/src/components/utils/createToastNotifier/constants";

/**
 * Creates toast notifications from django messages
 *
 * @param autoClose - Time in milliseconds to close the toast, false to disable
 * @default false
 *
 * @param eventName - Event name to listen
 * @default "newDjangoMessagesAvailable"
 */
export const useCreateToast = (
  autoClose: number | false = false,
  eventName: string = "newDjangoMessagesAvailable"
) => {
  useEffect(() => {
    const createToasts = async () => {
      const response = getDjangoMessages();

      if (!response || !response.data) return;

      const { data, clear } = response;

      for (const { message, level } of data) {
        toast(message, {
          type: level as TypeOptions,
          ...TOAST_CONFIG,
          autoClose,
        });
      }

      clear();
    };

    const response = getDjangoMessages();

    if (!response) return;

    if (response.data?.length > 0) {
      createToasts();
      return;
    }

    window.addEventListener(eventName, createToasts);

    return () => window.removeEventListener(eventName, createToasts);
  }, [autoClose, eventName]);
};
