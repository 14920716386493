/**
 * Creates a debounced function that delays invoking the provided `callback`
 * until after the specified `delay` has elapsed since the last time it was invoked.
 *
 * Useful for optimizing performance in scenarios like search input handling, window resizing, or API requests.
 *
 * @template T - A function that accepts any arguments and returns any value.
 * @param {T} callback - The function to debounce.
 * @param {number} [delay] - The delay in milliseconds before executing the callback.
 * @default 300
 *
 * @returns {(...args: Parameters<T>) => void} - A debounced version of the `callback` function.
 *
 * @example
 * // Debounce an API search request:
 * const handleSearch = debounce((query: string) => {
 *   fetchData(query);
 * }, 500);
 *
 * handleSearch("Hello");
 * handleSearch("Hello, Wo"); // Previous call is canceled
 * handleSearch("Hello, World"); // Only this call runs after 500ms
 */
export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  delay = 300
) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>): void => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};
