import { useState } from "react";
import { ItemsContainerItem } from "../../../../types";
import { UseSelectedItems } from "./types";

export const useSelectedItems = <
  Item extends ItemsContainerItem,
  FilterBy extends keyof Item
>({
  items,
}: UseSelectedItems<Item, FilterBy>) => {
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const areAllItemsSelected: boolean = items?.length === selectedItems?.length;

  const handleAddItem = (itemToAdd: Item) => {
    //Check if there's already an item with the same title
    const filteredItems = selectedItems.filter(
      (selectedItem) => selectedItem.title !== itemToAdd.title
    );

    //If the filtered items are less than the selected items
    //re-set the selected items since the item to add was already selected
    if (filteredItems.length < selectedItems.length) {
      return setSelectedItems(filteredItems);
    }

    setSelectedItems((prev) => [...prev, itemToAdd]);
  };

  const handleResetSelectedCodes = () => {
    setSelectedItems([]);
  };

  const handleSelectItems = (items: Item[]) => {
    setSelectedItems(items);
  };

  return {
    selectedItems,
    handleAddItem,
    handleResetSelectedCodes,
    handleSelectItems,
    areAllItemsSelected,
  };
};
