import { TableFilterOptions } from "@root/src/components/Table/types";

export const getFiltersWithoutExclusions = (
  filters: TableFilterOptions,
  filtersExclusions: string[]
): TableFilterOptions =>
  Object.entries(filters)
    .filter(([key]) => !filtersExclusions?.includes(key))
    .reduce((previousValue, [key, value]) => {
      return (previousValue = {
        ...previousValue,
        [key]: value,
      });
    }, {});
