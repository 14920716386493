import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { AffiliateFacilitiesType } from "./types/affiliateFacilities";
import { UserDetails } from "@root/src/components/Table/templates/UserDetails";

export const AffiliateFacilities: FC = () => {
  const url: string = getURL("api:affiliate-facilities-list", {
    parent_lookup_pk: "current",
  });

  return (
    <Table<AffiliateFacilitiesType>
      id={"facility_id"}
      name="Affiliated Facilities"
      searchable
      tooltipHelper={{
        variant: "?",
        message:
          "A list of facilities that belong to a parent organization of which your facility is a part.",
      }}
      expandableRowContent={{
        component: UserDetails,
        props: {
          expandableRow: {
            id: "facility_id",
            primaryMessage: "Primary Contact",
            options: {
              Name: ["primary_contact", "full_name"],
              Title: ["primary_contact", "role"],
              Phone: ["primary_contact", "formatted_office_number"],
              Fax: ["primary_contact", "formatted_office_number"],
              Email: ["primary_contact", "email"],
            },
          },
        },
      }}
      headers={[
        { title: "FACILITY", key: "name" },
        { title: "TYPE", key: "facility_type" },
        { title: "LOCATION", key: "location" },
      ]}
      url={url}
      actions={["view_profile", "submit_disposition_form"]}
    />
  );
};
