import { useState } from "react";

import { Review } from "./types";

export const useReview = () => {
  const [review, setReview] = useState<Review>(null);

  const reviewHandler = (value: Review) => {
    setReview(value);
  };

  return { review, reviewHandler };
};
