import { CheckBox } from "@/components/CheckBox";
import { DropdownLinkItem } from "./components/DropdownLinkItem";
import { DropdownMenuItem } from "./components/DropdownMenuItem";
import { DropdownModalItem } from "./components/DropdownModalItem";
import { DropdownModalItemProps } from "./components/DropdownModalItem/types";
import { DropdownMenuVariants } from "../../types";
import { GetDropdownMenuVariantProps } from "./types";
import { DropdownLinkItemProps } from "./components/DropdownLinkItem/types";

export const getDropdownMenuVariant: <
  Variant extends DropdownMenuVariants,
  Value
>({
  option,
  rightAlignment,
  onClick,
  selectedOption,
  icon,
}: GetDropdownMenuVariantProps<Variant, Value>) => JSX.Element = ({
  option,
  rightAlignment,
  selectedOption,
  icon,
}) =>
  ({
    "standard": (
      <DropdownMenuItem
        {...{
          icon,
          rightAlignment,
          ...option,
        }}
      />
    ),
    "checkbox": (
      <CheckBox
        size="small"
        className={`dropdown-menu-checkbox ${
          rightAlignment ? "right-alignment" : ""
        }`}
        label={option.title.toString()}
        defaultChecked={selectedOption === option.title}
      />
    ),
    "link": (
      <DropdownLinkItem
        {...{
          ...(option as DropdownLinkItemProps),
          icon,
          rightAlignment,
        }}
      />
    ),
    "modal": (
      <DropdownModalItem
        {...{ ...(option as DropdownModalItemProps), icon, rightAlignment }}
      />
    ),
  }[option.variant || "standard"]);
