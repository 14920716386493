import { axiosRequest } from "@/hooks/axiosRequest";
import { getURL } from "@/utils/getURL";
import { NoteData } from "../../types";
import { createToastNotifier } from "@root/src/components/utils/createToastNotifier";

export const createComment = async (data: NoteData, id: string) => {
  const toastUpdate = createToastNotifier("create-note");
  try {
    const url = getURL("api:internal-orders-add-comment", {
      pk: id,
    });

    const {
      status,
      data: { detail },
    } = await axiosRequest<NoteData, { detail: string }>({
      url,
      method: "POST",
      data,
    });

    const message = detail || "Note added successfully";

    toastUpdate({
      status,
      message,
    });
  } catch (error: any) {
    toastUpdate({
      status: 500,
      message: error.detail || "Failed to add note",
    });
  }
};
