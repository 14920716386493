import { AlertActionsProps } from "./types";
import { isActionDefined } from "./utils/isActionDefined";
import { Button } from "@/components/Button";

import "./scss/index.scss";

export const AlertActions = ({ color, actions }: AlertActionsProps) => {
  if (!actions) return null;

  return (
    <div
      data-testid="alert-actions"
      className={`alert-actions-container ${color}`}
    >
      {actions
        .filter(isActionDefined)
        .map(({ label, onClick: handleOnClick }) => (
          <Button
            key={label}
            {...{ label, onClick: handleOnClick, variant: "link", color }}
          />
        ))}
    </div>
  );
};
