import { KeyedMutator } from "swr";

import { activeFilters } from "@/hooks/useSearchParams/utils/activeFilters";
import { SearchParam } from "./types";
import { PaginatedResponse } from "@/types";

export const useSearchParams = <T extends Record<string, unknown>>(
  mutation?: KeyedMutator<PaginatedResponse<T>>
): SearchParam => {
  const { location, history } = window;
  const { search, pathname } = location;
  const searchParams: URLSearchParams = new URLSearchParams(search);

  const newUrl = () => `${location.pathname}?${searchParams.toString()}`;

  const updateSearchParams = (name: string, value: string) => {
    searchParams.set(name, value);
    history.pushState(null, "", newUrl());
    mutation?.();
  };

  const deleteSearchParam = (name: string, value?: string) => {
    searchParams.delete(name, value);
    history.pushState(null, "", newUrl());
    mutation?.();
  };

  const deleteAllSearchParams = () => {
    const newSearchParams: URLSearchParams = new URLSearchParams();
    const query: string = "q";
    const queryParamValue: string | null = searchParams.get(query);
    queryParamValue && newSearchParams.set(query, queryParamValue);
    history.pushState(null, "", newUrl());
    mutation?.();
  };

  const currentUrlPath: string[] = pathname
    .split("/")
    .filter((nonEmptyValue) => nonEmptyValue);

  const filters = activeFilters<T>(mutation);

  return {
    searchParams,
    updateSearchParams,
    deleteSearchParam,
    deleteAllSearchParams,
    currentUrlPath,
    ...filters,
  };
};
