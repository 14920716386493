import { IoClose } from "react-icons/io5";

import { Icon } from "@/components/Icon";
import { AlertCloseButtonProps } from "./types";

import "./scss/index.scss";

export const AlertCloseButton = ({
  closeButton,
  color,
  handleVisibility,
}: AlertCloseButtonProps) =>
  closeButton ? (
    <Icon
      testID="close-button"
      color={color}
      icon={IoClose}
      variant="link"
      onClick={handleVisibility}
      className="alert-close-button"
    />
  ) : null;
