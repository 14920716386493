import { useState } from "react";

import { MailerData, UseMailerDataProps } from "./types";

export const useMailerData = ({
  recipient,
  superUsers,
  ...mailerProps
}: UseMailerDataProps) => {
  const [mailerData, setMailerData] = useState<MailerData>({
    subject: "",
    message: "",
    email_addresses: [],
    facility_uuids: [],
    cc: superUsers || [],
    ...mailerProps,
  });

  const handleMailerData = <T extends keyof MailerData>(
    key: T,
    value: MailerData[T]
  ) =>
    setMailerData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

  const hasSubject: boolean = !!mailerData.subject.length;
  const hasMessage: boolean = !!mailerData.message.length;

  const getEmails = () => {
    const getEmails = {
      user: mailerData.email_addresses.length,
      facility: mailerData.facility_uuids.length,
    };

    return !!getEmails[recipient];
  };

  return {
    mailerData,
    handleMailerData,
    hasSubject,
    hasMessage,
    hasEmail: getEmails(),
  };
};
