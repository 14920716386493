export const USER_DEFAULT = {
  user_id: 0,
  full_name: "",
  is_superuser: false,
  is_primary_contact: false,
  email: "",
  role: "",
  facility: {
    id: "",
    name: "",
  },
  is_active: false,
  permission_groups: [],
};
