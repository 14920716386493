import { DropdownMenu } from "@/components/DropdownMenu";
import {
  DropdownMenuProps,
  DropdownMenuVariants,
} from "@/components/DropdownMenu/types";

import "./scss/index.scss";

export const FilteredDropdownMenu = <
  Value,
  Variant extends DropdownMenuVariants,
>({
  open,
  options,
  ...props
}: DropdownMenuProps<Value, Variant> & {
  open?: boolean;
}) => {
  if (!open) return null;
  const isAnyOption: boolean = !options.length;
  if (isAnyOption && !open) return null;

  return options?.length ? (
    <DropdownMenu
      {...{
        options,
        ...props,
      }}
    />
  ) : (
    <div className="drop-down-no-results">No results</div>
  );
};
