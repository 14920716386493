import useSWR from "swr";

import { UseFetch, useFetchProps } from "./types";
import { PaginatedResponse } from "@/types";
import { useDelayedTableLoading } from "./hooks/useDelayedTableLoading";
import { tableFetcher } from "./api/tableFetcher";

/**
 * Hook for fetching table records using SWR + Axios
 *
 * @param url - The constructed URL for fetching data
 * @param setIsTableLoading - Optional callback for setting loading state
 */
export const useFetch = <T extends Record<string, unknown> = {}>({
  url,
  setIsTableLoading,
}: useFetchProps): UseFetch<T> => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<
    PaginatedResponse<T>
  >(url, tableFetcher, {
    keepPreviousData: true,
  });
  const isRefreshing = isValidating && !!data;

  useDelayedTableLoading(isLoading, setIsTableLoading);

  const errorMessage: string = error
    ? typeof error === "string"
      ? error
      : error.message
    : null;

  return {
    data,
    error: errorMessage,
    isLoading,
    isValidating,
    isRefreshing,
    mutate,
  };
};
