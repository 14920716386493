import { axiosRequest } from "@/hooks/axiosRequest";
import { PaginatedResponse } from "@/types";
import { validateUrl } from "../../utils/validateURL";

export async function tableFetcher<T extends Record<string, unknown>>(
  url: string
): Promise<PaginatedResponse<T>> {
  try {
    validateUrl(url);
    const { data } = await axiosRequest<PaginatedResponse<T>>({ url });
    return data;
  } catch (error: any) {
    return {
      results: null,
      error:
        error.data.detail ||
        error.message ||
        "There was an error fetching the data",
    };
  }
}
