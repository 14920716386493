import { AlertActions } from "./components/AlertActions";
import { AlertDetailsContainerProps } from "./types";

import "./scss/index.scss";

export const AlertInfoDetailsContainer = ({
  header,
  description,
  actions,
  color,
}: AlertDetailsContainerProps) => (
  <div
    data-testid="alert-info-details-container"
    className="alert-details-container"
  >
    <div className="alert-headers-container">
      {header ? <div className={`header--${color}`}>{header}</div> : null}
      <div className={`description--${color}`}>{description}</div>
    </div>
    <AlertActions {...{ color, actions }} />
  </div>
);
