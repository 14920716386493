import { Badge } from "@/components/Badge";
import { CrossIcon } from "./assets/CrossIcon";
import { EmailContainerProps } from "./types";
import { RecipientKeys } from "../../../../types";
import { debounce } from "@/utils/debounce";
import { getClassName } from "@/utils/getClassName";

import "./scss/index.scss";

export const EmailsContainer = <T extends RecipientKeys>({
  inputRef,
  onChange,
  onRemoveRecord: handleRemoveRecord,
  recipientDetails,
  activeSuggestions,
  errorMessage,
  autoFocus = false,
}: EmailContainerProps<T>) => {
  const { getBadgeLabel, badgeColor, badgeVariant } = recipientDetails;

  const delay: number = 680;
  const debounceOnChange = debounce(onChange, delay);
  const setInputFocus = () => inputRef.current?.focus();
  const containerClassName = getClassName("emails-container", {
    "error": !!errorMessage,
  });

  return (
    <div className={containerClassName} onClick={setInputFocus}>
      {activeSuggestions.map((record) => {
        const label = getBadgeLabel(record);

        return (
          <Badge
            key={label}
            label={label}
            size={"small"}
            color={badgeColor}
            variant={badgeVariant}
            onClick={() => {
              setInputFocus();
              return handleRemoveRecord(record);
            }}
            rightIcon={CrossIcon}
          />
        );
      })}

      <input
        ref={inputRef}
        autoFocus={autoFocus}
        onChange={(event) => {
          const value: string = event.target.value;
          return debounceOnChange(value);
        }}
        type="text"
        className="input"
      />
    </div>
  );
};
