import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { BlockIcon } from "../assets";

const url: string = getURL("api:internal-packages-online");
const tableOptionsUrl: string = getURL("api:internal-packages-schema-options");

export const OnlinePackages: FC = () => {
  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      options={{
        url: tableOptionsUrl,
        filters: {
          filtersToExclude: [
            "buyer",
            "is_private",
            "shared_with",
            "pkg_status",
          ],
        },
      }}
      name="Online Packages"
      searchable
      headers={[
        { title: "#", key: "id" },
        { title: "MODIFIED", key: "last_modified", type: "date" },
        { title: "SELLER", key: "seller" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "LISTED FOR", key: "listed_for" },
        { title: "AUDIENCE", key: "audience" },
        {
          title: "BLOCKED",
          key: "has_block",
          type: "boolean",
          properties: {
            booleanType: {
              true: <BlockIcon />,
            },
          },
        },
      ]}
      url={url}
    />
  );
};
