/**
 * Converts a string into a URL-friendly slug.
 *
 * This function:
 * - Converts all characters to lowercase.
 * - Removes extra spaces and filters out standalone hyphens.
 * - Replaces spaces with hyphens (`-`).
 * - Trims leading and trailing spaces.
 *
 * @param {string} tab - The input string to slugify.
 * @returns {string} - The slugified string.
 *
 * @example
 * slugify("Hello World"); // "hello-world"
 * slugify(" React  JS "); // "react-js"
 * slugify("A--B"); // "a--b"
 * slugify(" - Trimmed- "); // "trimmed-"
 */
export const slugify = (tab: string): string =>
  tab
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .filter((word) => word !== "-")
    .join("-");
