import { memo } from "react";

import { NavbarHeader } from "./components/navbarHeader";
import { NavbarSearchResults } from "./components/navbarSearchResults";
import { MemoizedTableNavbarSearchResultsProps } from "./types";

export const MemoizedTableNavbarSearchResults = memo(
  ({
    allRecordsCount,
    className,
    search,
    name,
  }: MemoizedTableNavbarSearchResultsProps) => (
    <div data-testid="table-navbar-header-container" className={className}>
      <NavbarHeader {...{ search, name }} />
      <NavbarSearchResults {...{ allRecordsCount, search, name }} />
    </div>
  ),
  (prev, next) => {
    const isRefreshing = prev.isRefreshing !== next.isRefreshing;
    const hasNewCount = prev.allRecordsCount === next.allRecordsCount;
    return isRefreshing && hasNewCount;
  }
);
