import { UserDetailsProps } from "./types";
import { Badge } from "@root/src/components/Badge";

import "./scss/index.scss";

export const MailerUserDetails = ({ record: user }: UserDetailsProps) => (
  <div className="user-details-container">
    <div className="user-details">
      <div className="user-metadata">
        {user.full_name} {`<${user.email}>`}
      </div>
      <div className="user-sub-metadata">{user.facility.name}</div>
    </div>
    {user.is_primary_contact ? (
      <Badge label={"PRI"} color="neutral" size="small" />
    ) : null}
  </div>
);
