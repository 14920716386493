import { FC } from "react";
import { RxCross2 } from "react-icons/rx";

import { TableFiltersProps } from "./types";
import { Button } from "@/components/Button";
import { TableFiltersLabelGenerator } from "./components/TableFiltersLabelGenerator";
import { useTableFiltersLogic } from "./hook/useTableFiltersLogic";

import "./styles.scss";

export const TableFilters: FC<TableFiltersProps> = ({ tableFilterOptions }) => {
  const { thereIsNoFilters, filters, removeFilter, removeAllFilters } =
    useTableFiltersLogic(tableFilterOptions);

  if (thereIsNoFilters) return null;

  return (
    <div className="filters-container">
      <TableFiltersLabelGenerator
        {...{ filters, removeFilter, tableFilterOptions }}
      />
      <Button
        label="Clear Filters"
        size={"small"}
        color={"primary"}
        variant={"link"}
        onClick={removeAllFilters}
        rightIcon={RxCross2}
      />
    </div>
  );
};
