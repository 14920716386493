import { InputMask } from "@react-input/mask";
import { ForwardedRef, forwardRef } from "react";
import { BiCalendar } from "react-icons/bi";

import { Icon } from "@/components/Icon";
import { CustomDateInputProps } from "./types";
import { handleKeyUp } from "./utils/handleKeyUp";
import { useCustomDatePickerInputLogic } from "./hooks/useCustomDatePickerInputLogic";

export const CustomDatePickerInput = forwardRef(
  (
    {
      value,
      onClick: openDatePickerModal,
      ...onKeyDownprops
    }: CustomDateInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const {
      inputStyle,
      input,
      dateValue,
      mask,
      handleInputState,
      isInvalid,
      handleInvalidState,
      handleUpdateDateRange,
    } = useCustomDatePickerInputLogic(value);

    return (
      <div className="table-navbar--input-mask-container">
        <InputMask
          className={`${isInvalid ? "invalid-date" : ""}`}
          ref={ref}
          onClick={openDatePickerModal}
          style={inputStyle}
          value={input || dateValue || mask}
          mask={mask}
          onMask={(event) => handleInputState(event.target.value)}
          onKeyUp={(event) => {
            handleKeyUp({
              event,
              handleUpdateDateRange,
              handleInvalidState,
              isInvalid,
              ...onKeyDownprops,
            });
          }}
          replacement={{ M: /\d/, D: /\d/, Y: /\d/ }}
          showMask
        />
        {isInvalid ? (
          <span className="date-picker-error-message">Invalid date</span>
        ) : null}
        <Icon
          onClick={openDatePickerModal}
          className="table-navbar--input-mask-icon"
          variant={"link"}
          icon={BiCalendar}
          color="neutral"
        />
      </div>
    );
  }
);
