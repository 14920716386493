import { slugify } from "@/utils/slugify";
import { Tabs } from "../../types";
import { useCurrentTab } from "../useCurrentTab";

export const useTabsLogic = (tabs: Tabs, activeTab: string) => {
  const { currentTab, handleActiveTab } = useCurrentTab(activeTab);

  const { title: tab, subTabs } =
    tabs.find(({ title }) => slugify(title) === currentTab) ?? {};

  const isTabActive: boolean = slugify(tab ?? "") === activeTab;

  return {
    handleActiveTab,
    subTabs,
    isTabActive,
  };
};
