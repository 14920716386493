import { TableRowHeadersProps } from "./types";
import { useTruncate } from "@/components/Table/hooks/useTruncate";
import { generateToolTipContent } from "./utils/generateTooltipContent";
import { getCellValue } from "./utils/getCellValue";

import "./scss/index.scss";

export const TableRowItemContent = ({
  rowHeaders,
  record,
}: TableRowHeadersProps) => {
  const { registerElement, getTooltipId } = useTruncate({
    accessChild: true,
  });

  return rowHeaders.map(({ key, ...props }) => {
    const cellValue: JSX.Element = getCellValue({
      record,
      key,
      ...props,
    });
    const headerKey = Array.isArray(key) ? key[0] : key;

    return (
      <td key={headerKey} className="table-row__cell">
        <div
          data-tooltip-content={generateToolTipContent(cellValue)}
          data-tooltip-id={getTooltipId(headerKey)}
          ref={registerElement(headerKey)}
          className={`table-row__header`}
        >
          {cellValue}
        </div>
      </td>
    );
  });
};
