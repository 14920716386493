import { useContext } from "react";

import { TableContext } from "@/components/Table/context";
import { CheckBox } from "@root/src/components/CheckBox";

export const TableHeadersBulkAction = () => {
  const {
    toggleAllCheckboxes,
    bulkActions,
    areAllCheckboxesSelected,
    isTableLoading,
  } = useContext(TableContext);

  return bulkActions && !isTableLoading ? (
    <th style={{ width: "36px" }}>
      <CheckBox
        checked={areAllCheckboxesSelected}
        onClick={(isChecked: boolean) => toggleAllCheckboxes?.(isChecked)}
      />
    </th>
  ) : null;
};
