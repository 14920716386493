import { motion, AnimatePresence } from "motion/react";

import { CheckBox } from "@/components/CheckBox";
import { ItemsListWrapper } from "./components/ItemsListWrapper";
import { Button } from "@/components/Button";
import { ItemsContainerItem } from "../types";
import { ItemsListProps } from "./types";
import { ItemsListNoResults } from "./components/ItemsListNoResults";
import { GENERIC_FADE_ANIMATION } from "@root/src/constants";

import "./scss/index.scss";

export const ItemsList = <
  Item extends ItemsContainerItem,
  FilterBy extends keyof Item
>({
  items,
  selectedItems,
  handleAddItem,
  disabled,
  handleSelectItems,
  getItemLabel,
}: ItemsListProps<Item, FilterBy>) => {
  if (!items || disabled) return <ItemsListWrapper>{null}</ItemsListWrapper>;

  const hasItems: boolean = !!items?.length;
  if (!hasItems) return <ItemsListNoResults />;

  return (
    <ItemsListWrapper>
      <AnimatePresence>
        {items.map((item) => {
          const isChecked = selectedItems.some(
            (selectedItem) => selectedItem.title === item.title
          );
          return (
            <motion.li
              {...GENERIC_FADE_ANIMATION}
              layout
              key={item.title}
              className="items-list__item"
            >
              <CheckBox
                checked={isChecked}
                onClick={() => handleAddItem(item)}
                label={getItemLabel(item)}
              />
              <Button
                className="items-list__button"
                variant="link"
                label={"Only"}
                onClick={() => handleSelectItems([item])}
                color="primary"
              />
            </motion.li>
          );
        })}
      </AnimatePresence>
    </ItemsListWrapper>
  );
};
