export const addClass = (
  className: string,
  classToAdd: string
): string | void => {
  if (className.includes(` ${classToAdd}`))
    return console.error(`Class "${classToAdd}" already being used`);

  className = `${className} ${classToAdd}`;

  return className;
};
