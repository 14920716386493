import { InternalBlockedFacilitiesType } from "../../types";

export const getBlockByMoreCount = ((
  blockedBy: InternalBlockedFacilitiesType["blocked_by"]
) => {
  const [_, ...restOfFacilities] = blockedBy;
  const restOfFacilitiesLength = restOfFacilities.length;

  if (!restOfFacilitiesLength) return null;
  return `${restOfFacilitiesLength} more`;
}) as <InternalBlockedFacilitiesType>(
  value: InternalBlockedFacilitiesType
) => string;
