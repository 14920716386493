import { Tooltip } from "react-tooltip";
import { IoMdClose } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { useContext } from "react";
import { motion, AnimatePresence } from "motion/react";

import { Icon } from "@/components/Icon";
import { Button } from "@/components/Button";
import { TableContext } from "@/components/Table/context";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { GENERIC_FADE_ANIMATION } from "@/constants";

export const TableNavbarShareButton = () => {
  const currentURL: string = window.location.href;
  const { clickedOutside, ref, clickedOutsideHandler, clickedOutsideToggle } =
    useOutsideClick();

  const { isTableLoading } = useContext(TableContext);

  return (
    <div ref={ref} className="share-container">
      <Icon
        icon={IoShareSocial}
        variant={"link"}
        color="neutral"
        size="medium"
        onClick={clickedOutsideToggle}
        disabled={isTableLoading}
      />
      {clickedOutside ? (
        <AnimatePresence>
          <motion.div {...GENERIC_FADE_ANIMATION} className="share-modal">
            <div className="header-row">
              <div className="header">Share URL</div>
              <Icon
                icon={IoMdClose}
                variant={"link"}
                color="neutral"
                size="small"
                onClick={() => clickedOutsideHandler(false)}
              />
            </div>
            <div className="share-modal-content">
              <div className="url-container">{currentURL}</div>
              <div className="share-modal-note">
                <span className="note-highlight">NOTE:</span> Only people who
                can already log in to Bloodbuy can use this link.
              </div>
              <a
                data-tooltip-id="copy-tooltip"
                data-tooltip-content="Copied"
                data-tooltip-delay-hide={1000}
              >
                <Button
                  label={"Copy Link"}
                  size={"small"}
                  variant={"filled"}
                  color="primary"
                  onClick={() =>
                    navigator.clipboard.writeText(window.location.href)
                  }
                />
              </a>
              <Tooltip openOnClick id="copy-tooltip" />
            </div>
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  );
};
