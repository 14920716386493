import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { handleAxiosError } from "./handleAxiosRequest";
import { DEFAULT_HEADERS } from "./constants";

export const axiosRequest = async <Data = any, Response = any>({
  method = "get",
  ...config
}: AxiosRequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  try {
    const response = await axios({
      method,
      headers: DEFAULT_HEADERS,
      ...config,
    });

    const data = response.data;

    return {
      ...response,
      data,
    };
  } catch (error) {
    throw handleAxiosError(error);
  }
};
