import { CheckBoxProps } from "./types";

import "./scss/index.scss";

/**
 * A custom styled checkbox component with an optional label and internal toggle state.
 *
 * This component allows you to create a checkbox with a custom design and supports
 * an optional callback to handle state changes.
 *
 * Props:
 * @typedef {Object} CheckBoxProps
 * @property {string} [label] - Optional label text that will be displayed next to the checkbox.
 * @property {boolean} [defaultChecked=false] - Initial checked state of the checkbox when it is first rendered. This is used as a starting point for its internal state.
 * @property {boolean} [checked] - Controlled checked state. If passed, this makes the checkbox controlled and overrides internal state.
 * @property {function} [onClick] - A callback function that is triggered when the checkbox is clicked. The function receives the updated checked value as its parameter.
 * @property {string} [id] - An optional unique identifier for the checkbox input element.
 *
 * @example
 * // Basic Usage with Default State
 * <CheckBox label="Accept Terms and Conditions" />
 *
 * @example
 * // Using a Default Checked State
 * <CheckBox label="Subscribe to Newsletter" defaultChecked={true} />
 *
 * @example
 * // Controlled Checkbox with External State
 * const [isChecked, setIsChecked] = useState(false);
 * return (
 *   <CheckBox
 *     label="Enable Notifications"
 *     checked={isChecked}
 *     onClick={(value) => setIsChecked(value)}
 *   />
 * );
 *
 * @example
 * // Checkbox without Label
 * <CheckBox defaultChecked={false} />
 *
 * @returns {JSX.Element} The `CheckBox` component.
 */
export const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  onClick,
  id,
  disabled,
  className,
  checked,
  size,
}) => (
  <label
    className={`checkbox-container ${className} ${disabled ? "disabled" : ""}`}
  >
    {label ? (
      <span
        data-testid="label"
        className={`checkbox-container-label ${disabled ? "disabled" : ""}`}
      >
        {label}
      </span>
    ) : null}
    <input
      type="checkbox"
      disabled={disabled}
      checked={checked}
      onClick={({ currentTarget: { checked } }) => {
        onClick?.(checked);
      }}
      id={id}
      readOnly
    />
    <span
      data-testid="checkmark"
      className={`checkbox-container-checkmark ${size ? size : ""} ${
        disabled ? "disabled" : ""
      }`}
    ></span>
  </label>
);
