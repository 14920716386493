import { SuggestionsProps } from "./types";
import { MailerRecipientsMap, RecipientKeys } from "../../../../types";
import { resetInputValues } from "./utils/resetInputValues";

export const MailerSuggestions = <T extends RecipientKeys>({
  inputRef,
  suggestions,
  onAddRecord,
  resetInputValue,
  detailsToRender,
}: SuggestionsProps<T>) => {
  const hasSuggestions = !!suggestions?.length;
  const input = inputRef.current;
  const handleOnClick = (suggestion: MailerRecipientsMap[T]) => {
    resetInputValues(input, resetInputValue);
    input?.focus();
    return onAddRecord(suggestion);
  };

  return (
    <ul className={`list-element drop-shadow-x-large`}>
      {hasSuggestions ? (
        suggestions.map((suggestion, index: number) => (
          <li
            onClick={() => handleOnClick(suggestion)}
            className="li-element"
            key={index}
          >
            {detailsToRender(suggestion)}
          </li>
        ))
      ) : (
        <li className="li-element no-suggestions">No suggestions</li>
      )}
    </ul>
  );
};
