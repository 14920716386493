import parse from "html-react-parser";

import { getURL } from "@/utils/getURL";
import { PostMailerReview } from "./types";
import { axiosRequest } from "@/hooks/axiosRequest";

export const postMailerReview = async ({
  reviewHandler,
  data,
}: PostMailerReview) => {
  const url = getURL("api:custom-email-message-review");

  try {
    const { data: response } = await axiosRequest({
      url,
      method: "post",
      data,
    });

    reviewHandler(parse(response));
  } catch (error) {
    console.error(error);
  }
};
