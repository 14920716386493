import { RecipientKeys } from "../../../../types";
import { MailerRecipientDetailsMap } from "../../types";
import { UseRecipientsDetailsMapProps } from "./types";
import { getUserLogic } from "./utils/getUserLogic";
import { getFacilityLogic } from "./utils/getFacilityLogic";

type RecipientLogicMap<T extends RecipientKeys> = Record<
  T,
  MailerRecipientDetailsMap[T]
>;

export function getRecipientsDetailsMap<T extends RecipientKeys>({
  recipient,
}: UseRecipientsDetailsMapProps<T>): MailerRecipientDetailsMap[T] {
  const recipientLogicMap = {
    user: getUserLogic(),
    facility: getFacilityLogic(),
  } as Partial<RecipientLogicMap<T>>;

  return recipientLogicMap[recipient] as MailerRecipientDetailsMap[T];
}
