import { useRef } from "react";

import { CheckBox } from "@/components/CheckBox";
import { Button } from "@/components/Button";
import { AddOrderNoteModalProps, NoteData } from "./types";
import { useComment } from "./useComment";
import { createComment } from "./api/createComment";

import "./scss/index.scss";

export const AddOrderCommentModal = ({
  onCloseModal,
  orderId,
}: AddOrderNoteModalProps) => {
  const { comment, debounceUpdateComment } = useComment();
  const sendEmailRef = useRef(false);

  const handleSubmit = () => {
    const noteData: NoteData = {
      comment,
      send_mail: sendEmailRef.current,
    };

    onCloseModal();
    createComment(noteData, orderId.toString());
  };

  return (
    <div className="order-note-modal">
      <h1 className="order-note-modal__header">Add Order Comment</h1>
      <textarea
        className="order-note-modal__textarea"
        placeholder="Please note any details about this order so we can follow-up."
        onChange={({ target: { value } }) => debounceUpdateComment(value)}
      />
      <CheckBox
        onClick={(value) => (sendEmailRef.current = value)}
        label="Send email Notifications (will be sent to all parties)"
      />
      <div className="order-note-modal__buttons">
        <Button
          onClick={onCloseModal}
          label={"Cancel"}
          color="neutral"
          variant="outlined"
        />
        <Button disabled={!comment} onClick={handleSubmit} label={"Post"} />
      </div>
    </div>
  );
};
