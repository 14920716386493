import { useState } from "react";

export const useInputValue = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const handleInputValue = (value: string) => setInputValue(value);
  const resetInputValue = () => setInputValue("");

  return {
    inputValue,
    handleInputValue,
    resetInputValue,
  };
};
