import { getURL } from "@/utils/getURL";
import { MailerFacilityDetails } from "../../../../../../components/mailerInput/components/MailerFacilityDetails";
import { FacilityMailerDetails } from "../../../../../../types";
import { MailerRecipientDetailsMap } from "../../../../types";

export const getFacilityLogic = (): Omit<
  MailerRecipientDetailsMap["facility"],
  "records"
> => ({
  type: "facility",
  bccContent: (facilities) =>
    (facilities as FacilityMailerDetails[]).map(({ name }) => name).join(", "),
  suggestionsUrl: getURL("api:facilities-list"),
  removeRecipient: (facilityToRemove, activeFacilities) =>
    activeFacilities.filter((record) => record.id !== facilityToRemove.id),
  component: (record: FacilityMailerDetails) => (
    <MailerFacilityDetails record={record} />
  ),
  getSuggestionsFilter: (
    record: FacilityMailerDetails,
    activeRecords: FacilityMailerDetails[]
  ) => !activeRecords.some((activeRecord) => activeRecord.id === record.id),
  getBadgeLabel: (record: FacilityMailerDetails) =>
    `Email all users at ${record.name}`,
  badgeColor: "success",
  badgeVariant: "filled",
});
