import { FC } from "react";
import { AnimatePresence, motion } from "motion/react";

import { AlertProps } from "./types";
import { AlertInfo } from "./components/AlertInfo";

import { useVisibility } from "@/hooks/useVisibility";
import { AlertCloseButton } from "./components/AlertCloseButton";
import { GENERIC_FADE_ANIMATION } from "@root/src/constants";

import "./scss/index.scss";

/**
 * {@link Alert | Alert}
 *
 * @param header - Primary message
 * @param description - Sub message
 * @param actions - Conditional functions to render, max (2)
 * @param icon - Conditional Icon to render inside the component
 * @param color - Color to render the component, options "primary" | "destructive" | "success" | "warning" | "neutral"
 * @param closeButton - Boolean to toggle the close button
 *
 * @returns JSX element, Alert component
 *
 * @example
 * Simple usage:
 * ```
 * <Alert description={"Test"} color={"primary"}/>
 * ```
 *
 * @example
 * With header:
 * <Alert description={"Test"} color={"primary"} header={'test-header'}/>
 *
 * @example
 * With actions
 * <Alert description={"Test"} color={"primary"} header={'test-header'} actions={[{ label: "test", onClick: () => alert(1) }]} />
 *
 * @example
 * With icon
 * <Alert description={"Test"} color={"primary"} icon={IOIcon}/>
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4413 - Jira Story}
 *
 * {@link https://www.figma.com/file/YSqy7gfEnOCAYZYHn88ZwU/Bloodbuy-Alerts-Inline?node-id=0%3A1&t=QX2nRYyLTha6NNvI-0 - Figma design}
 */
export const Alert: FC<AlertProps> = ({
  header,
  description,
  actions,
  icon,
  color,
  closeButton,
}): JSX.Element | null => {
  const { visible, handleVisibility } = useVisibility({ defaultValue: true });

  if (!visible) return null;

  return (
    <AnimatePresence>
      <motion.div
        {...GENERIC_FADE_ANIMATION}
        className={`alert-container ${color}`}
      >
        <AlertInfo {...{ header, description, actions, icon, color }} />
        <AlertCloseButton {...{ closeButton, color, handleVisibility }} />
      </motion.div>
    </AnimatePresence>
  );
};
