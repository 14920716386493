import useSWR from "swr";

import { axiosRequest } from "@/hooks/axiosRequest";
import { getURL } from "@/utils/getURL";
import { InternalFacilitiesTags } from "./types";

export const useInternalFacilitiesTags = () => {
  const url = getURL("api:internal-facilities-tags");
  const { data: response } = useSWR(
    url,
    () => axiosRequest<any, InternalFacilitiesTags[]>({ url }),
    {
      revalidateOnFocus: false,
    }
  );

  return response?.data || [];
};
