import { FacilityDetailsProps } from "./types";

import "./scss/index.scss";

export const MailerFacilityDetails = ({ record: facility }: FacilityDetailsProps) => (
  <div className="facility-details-container">
    <div className="upper-details">
      <div className="facility-metadata">{facility.name}</div>
      <div className="facility-metadata location">{facility.location}</div>
    </div>
    <div className="facility-sub-metadata">{facility.facility_type}</div>
  </div>
);
