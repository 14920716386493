import { CommonHeaderProps } from "@/components/Table/types/TableHeaders";
import { GetCellValueProps } from "./types";
import { getValue } from "./utils/getValue";
import { NoValue } from "./utils/getValue/components/NoValue";

/**
 * Generates the content for a table cell, supporting both simple and composite values.
 *
 * This function handles rendering the main cell value and any additional composite elements.
 * If composite elements are provided, it can optionally display a separator between the main value and the composite elements.
 *
 * @function getCellValue
 * @param {Object} params - The parameters for generating the cell value.
 * @param {CommonHeaderProps["properties"]} params.properties - The properties defining the cell's content, which may include composite configurations.
 * @param {...any} props - Additional properties passed down to the `getValue` function.
 * @returns {JSX.Element} A React fragment containing the rendered cell content.
 *
 * @example <caption>Rendering a cell with a simple value</caption>
 * const cellContent = getCellValue({
 *   properties: {
 *     // Define properties for the main value
 *   },
 *   data: rowData,
 * });
 *
 * @example <caption>Rendering a cell with composite values and a separator</caption>
 * const cellContent = getCellValue({
 *   properties: {
 *     composite: {
 *       elements: [
 *         { /* Properties for composite element 1 *\/ },
 *         { /* Properties for composite element 2 *\/ },
 *       ],
 *       separator: " - ",
 *     },
 *   },
 *   data: rowData,
 * });
 */
export const getCellValue = ({ properties, ...props }: GetCellValueProps) => {
  const currentProperties: CommonHeaderProps["properties"] = properties;
  const { elements = [], separator } = currentProperties?.composite || {};

  // Array of composite elements, filtered to remove falsy values
  const compositeElements = elements
    .map((element) => getValue({ ...props, ...element }))
    .filter(Boolean);

  // Check if composite elements should be rendered
  const hasCompositeElements = compositeElements.length > 0;
  const hasSeparator = !!separator;
  const shouldRenderSeparator = hasCompositeElements && hasSeparator;

  // Render cell value, or "NoValue" if value is falsy
  const value = getValue({ properties, ...props });
  const cellValue = value ? value : <NoValue />;

  return (
    <>
      {cellValue}
      {shouldRenderSeparator ? <span>{separator}</span> : null}
      {compositeElements}
    </>
  );
};
