import { FC } from "react";

import { UserDetailsHeader } from "./components/UserDetailsHeader";
import { UserDetailsList } from "./components/UserDetailsList";
import { UserDetailsProps } from "./types";
import { TableCollapseRowProps } from "@/components/Table/types/TableCollapseRow";
import { collapseRowOptions } from "./util/collapseRowOptions";

import "./scss/index.scss";

export const UserDetails: FC<UserDetailsProps> = ({
  record,
  expandableRow,
}) => {
  const { primaryMessage, secondaryMessage, options } = collapseRowOptions(
    record,
    expandableRow as TableCollapseRowProps
  );

  return (
    <div className="collapse-container">
      <UserDetailsHeader {...{ primaryMessage, secondaryMessage }} />
      <br />
      <UserDetailsList {...{ options }} />
    </div>
  );
};
