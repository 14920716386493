import {
  RecipientKeys,
  MailerRecipientsMap,
  UserMailerDetails,
  FacilityMailerDetails,
} from "../../../../types";
import { getActiveInactiveUsers } from "../getActiveInactiveUsers";
import { getFacilitiesByStatus } from "../getFacilitiesByStatus";

export const getDefault = <T extends RecipientKeys>(
  records: MailerRecipientsMap[T][] | MailerRecipientsMap[T],
  key: T
) => {
  const getUserDefaults = (users: UserMailerDetails[] | UserMailerDetails) => {
    const userList = Array.isArray(users) ? users : [users];

    const activeUsers = getActiveInactiveUsers(userList);
    const inactiveUsers = getActiveInactiveUsers(userList, false)
      .map(({ email }) => email)
      .join(", ");

    const [superUsers, filteredActiveUsers] = activeUsers.reduce(
      ([superusers, normalUsers], user) => {
        user.is_superuser ? superusers.push(user) : normalUsers.push(user);
        return [superusers, normalUsers];
      },
      [[], []] as [UserMailerDetails[], UserMailerDetails[]]
    );

    return {
      activeRecords: filteredActiveUsers,
      inactiveRecords: inactiveUsers,
      superUsers,
    };
  };

  const getFacilityDefaults = (
    facilities: FacilityMailerDetails[] | FacilityMailerDetails
  ) => {
    const facilitiesList = Array.isArray(facilities)
      ? facilities
      : [facilities];
    const activeFacilities = getFacilitiesByStatus(facilitiesList);
    const inactiveFacilities = getFacilitiesByStatus(facilitiesList, false)
      .map(({ name }) => name)
      .join(", ");

    return {
      activeRecords: activeFacilities,
      inactiveRecords: inactiveFacilities,
    };
  };

  const defaultMap: {
    [key in RecipientKeys]: (
      records: MailerRecipientsMap[key][] | MailerRecipientsMap[key]
    ) => {
      activeRecords: MailerRecipientsMap[key][];
      inactiveRecords: string;
      superUsers?: MailerRecipientsMap["user"][];
    };
  } = {
    user: (records) => getUserDefaults(records),
    facility: (records) => getFacilityDefaults(records),
  };

  return defaultMap[key](records);
};
