import { BiSearch } from "react-icons/bi";
import { FC } from "react";

import { CloseButton } from "./component/CloseButton";
import { TableNavbarInputProps } from "./types";
import { useTableNavbarInputLogic } from "./hooks/useTableNavbarInputLogic";

export const TableNavbarInput: FC<TableNavbarInputProps> = ({ searchable }) => { const { inputRef, input, debounceOnChangeHandler, onClick } =
    useTableNavbarInputLogic();

  if (!searchable) return null;

  return (
    <div className="table-navbar__input-container">
      <label htmlFor="input"></label>
      <input
        data-testid="table-navbar-input"
        className="table-navbar__input-container__input"
        type="text"
        id="input"
        placeholder="Search"
        ref={inputRef}
        onChange={debounceOnChangeHandler}
        defaultValue={input}
      />

      <div className="table-navbar__image-container">
        <BiSearch
          data-testid="input-image"
          className="table-navbar__image-container__image"
        />
      </div>

      {input ? <CloseButton {...{ onClick }} /> : null}
    </div>
  );
};
