import { EditUserPermissionsModal } from "../../components/EditUserPermissions/template";

export const editUserPermissionsAction = (record: object) => ({
  variant: "modal",
  title: "Edit User Permissions",
  modalTemplate: EditUserPermissionsModal,
  modalProps: {
    record,
  },
});
