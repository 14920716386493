import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { handleCreateOrder } from "../common/utils/handleCreateOrder";

const url: string = getURL("api:internal-orders-flagged");
const tableOptionsUrl: string = getURL("api:internal-orders-schema-options");

export const FlaggedOrders: FC = () => {
  return (
    <Table
      id={"facility_id"}
      sortBy="-flagged_at"
      onAddRecord={handleCreateOrder}
      options={{
        url: tableOptionsUrl,
        filters: { filtersToExclude: ["canceled_at", "is_flagged"] },
      }}
      name="Flagged Orders"
      searchable
      headers={[
        { title: "#", key: "id" },
        { title: "PLACED ON", key: "ordered_at", type: "date" },
        { title: "FLAGGED ON", key: "flagged_at", type: "date" },
        { title: "DELIVERED ON", key: "received_at", type: "date" },
        { title: "TYPE", key: "order_type" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "BUYER", key: "buyer" },
        { title: "SELLER", key: "seller" },
        { title: "AUDIENCE", key: "audience" },
        {
          title: "STATUS",
          key: "order_status",
          type: "badge",

          properties: {
            badgeColor: {
              "delivered": "success",
              "ordered": "neutral",
              "canceled": "destructive",
              "in-transit": "primary",
            },
          },
        },
      ]}
      url={url}
      actions={[
        "order_activity",
        "add_order_comment",
        "progress_order",
        "edit_order",
        "cancel_order",
      ]}
    />
  );
};
