import { DropdownMenuItemProps } from "./types";

import "./scss/index.scss";

export const DropdownMenuItem = <T,>({
  title,
  icon: Icon,
  className,
  value,
  rightAlignment,
  onClick,
}: DropdownMenuItemProps<T>) => (
  <button
    onClick={() => onClick?.({ value, title })}
    className={`dropdown-menu-item ${className ? className : ""} ${
      rightAlignment ? "right-alignment" : ""
    }`}
  >
    {Icon ? <Icon data-testid="dropdown-menu-item-icon" size={15} /> : null}
    <span lang="en">{title}</span>
  </button>
);
