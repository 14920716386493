import { BlockListModalHeaderProps } from "./types";
import "./scss/index.scss";

export const BlockListModalHeader = ({
  currentFacility,
  blockStatusLabel,
}: BlockListModalHeaderProps) => {
  return (
    <header>
      <h2 className="block-list-modal__title">
        Facilities {blockStatusLabel}: {currentFacility?.name}
      </h2>
    </header>
  );
};
