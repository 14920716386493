import { AnimatePresence, motion } from "motion/react";

import { DropdownMenuProps, DropdownMenuVariants } from "./types";
import { getDropdownMenuVariant } from "./utils/getDropdownMenuVariant";
import { isActive } from "./utils/isActive";
import { GENERIC_FADE_ANIMATION } from "@/constants";

import "./scss/index.scss";

/**
 * Represents a dropdown menu component.
 *
 * @remarks
 * This component renders a dropdown menu with customizable options.
 *
 * @param options - An array of options to be displayed in the dropdown menu.
 * @param checkbox - A boolean indicating whether to render checkbox items in the menu.
 * @param lineBreak - A boolean indicating whether to apply line breaks between menu items.
 * @param rightAlignment - A boolean indicating whether to align the dropdown menu to the right.
 * @param selectedOption - The selected option value.
 * @param icon - An optional custom icon component to be displayed alongside the menu.
 * @param onClick - An optional callback function triggered when an option is clicked.
 * @returns A JSX element representing the dropdown menu.
 *
 * @example
 *  Basic usage of DropdownMenu with simple options
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 * />
 *
 * @example
 *  DropdownMenu with custom icon
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   icon={BiArrowBack}
 * />
 *
 * @example
 *  DropdownMenu with onClick handler
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 2", value: "value2", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 3", value: "value3", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) }
 *   ]}
 * />
 *
 * @example
 *  DropdownMenu with individual onClick handlers in options and a general onClick handler
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 2", value: "value2", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   onClick={({ value, title }) => console.log(`Fallback selected: ${title} (${value})`)}
 * />
 *
 * @example
 *  DropdownMenu with right alignment
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   rightAlignment
 * />
 *
 * @example
 *  DropdownMenu with pre-selected option
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   selectedOption="value2" //Could be also the title value (Option 2)
 * />
 *
 * @example
 *  DropdownMenu with custom icon and customIcon
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1", customIcon: IconFromReactIcons },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   icon={DiApple}
 * />
 *
 * @example
 *  DropdownMenu with checkbox
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   checkbox
 * />
 */

export const DropdownMenu = <
  Value = string,
  Variant extends DropdownMenuVariants = undefined
>({
  options,
  lineBreak,
  className,
  header,
  onClick: handleOptionsOnClick,
  selectedOption,
  ...props
}: DropdownMenuProps<Value, Variant>) => (
  <AnimatePresence>
    <motion.ul
      {...GENERIC_FADE_ANIMATION}
      className={`drop-down-menu-list ${className}`}
    >
      {header ? <li className="drop-down-menu-list-title">{header}</li> : null}
      <AnimatePresence>
        {options?.map((option) => (
          <motion.li
            layout
            {...GENERIC_FADE_ANIMATION}
            key={option.title}
            className={`drop-down-list-item ${lineBreak ? "line-break" : ""} ${
              isActive<Value>(option, selectedOption) ? "active" : ""
            }`}
          >
            {getDropdownMenuVariant<Variant, Value>({
              option: {
                ...option,
                onClick: ({ title, value }) => {
                  handleOptionsOnClick?.({ value, title });
                  if ("onClick" in option) option?.onClick?.({ value, title });
                },
              },
              ...props,
              selectedOption,
            })}
          </motion.li>
        ))}
      </AnimatePresence>
    </motion.ul>
  </AnimatePresence>
);
