import { FC } from "react";
import { MdArrowDropDown } from "react-icons/md";

import { PaginationPageSizeOptions } from "../components/PaginationPageSizeOptions";
import { DetailsProps } from "./types";
import { Badge } from "@/components/Badge";

/**
 * Displays the pagination current page, and total number of records
 * Plus, the option to change the number of records per page
 *
 * @param currentPage - The current page of the pagination component
 * @param pageSize - The number of records to display per page
 * @param totalCount - Total number of records
 * @param pageSizeOptions - Page size options to update the current page size
 * @param pageSizeHandler - Set the current page size
 */
export const PaginationDetails: FC<DetailsProps> = ({ currentPage,
  pageSize,
  totalCount,
  pageSizeOptions,
  pageSizeHandler,
}) => {
  const paginationStartRange: number =
    currentPage > 1 ? pageSize * currentPage - (pageSize - 1) : currentPage;

  const paginationEndRange: number =
    pageSize * currentPage < totalCount ? pageSize * currentPage : totalCount;

  const badgeLabel: string = `${paginationStartRange} - ${paginationEndRange}`;

  return (
    <div className="pagination-info">
      <Badge
        label={badgeLabel}
        color="neutral"
        variant="secondary"
        rightIcon={MdArrowDropDown}
        className="pagination-info-badge"
      />
      <span>of {totalCount} entries</span>
      <PaginationPageSizeOptions
        {...{ pageSizeOptions, pageSizeHandler, pageSize }}
      />
    </div>
  );
};
