import { axiosRequest } from "@/hooks/axiosRequest";
import { getURL } from "@/utils/getURL";
import { UpdateBlockedFacilitiesProps } from "./types";
import { createToastNotifier } from "@root/src/components/utils/createToastNotifier";

export const updateBlockedFacilities = async ({
  facilitiesIds,
  facilityId,
}: UpdateBlockedFacilitiesProps) => {
  const url: string = getURL("api:internal-blocked-facilities-update", {
    uuid: facilityId,
  });

  const blockToastId = createToastNotifier();

  try {
    const {
      data: { detail },
      status,
    } = await axiosRequest({
      url,
      method: "post",
      data: {
        blocked_facilities: facilitiesIds,
      },
    });

    if (!detail) console.warn("Response detail not found");

    blockToastId({
      status,
      message: detail || "Blocked Facilities updated successfully",
    });
  } catch (error: any) {
    blockToastId({
      status: 500,
      message: error.data.detail || "Action failed, please try again later",
    });
  }
};
