import { FC } from "react";

import { PaginationLeftNavigationArrow } from "./components/PaginationLeftNavigationArrow";
import { PaginationRange } from "./components/PaginationRange";
import { PaginationRightNavigationArrow } from "./components/PaginationRightNavigationArrow";
import { PaginationOptionsProps } from "./types";

import "./scss/index.scss";

/**
 * @returns the different pagination options...
 * the next page, previous page, or select the current page
 * by the available options
 *
 * @param currentPage - Pagination current page
 * @param onPrevious - Function that changes the current page to the previous page
 * @param onNext - Function that changes the current page to the next page
 * @param paginationRange - Current range of available pages for the pagination
 * @param DOTS - String of dots
 * @param onSelect - Function that assign the current page
 * @param lastPage - Last page in the given pagination range
 */

export const PaginationOptions: FC<PaginationOptionsProps> = ({ currentPage,
  onPrevious,
  paginationRange,
  DOTS,
  onSelect,
  lastPage,
  onNext,
}) => (
  <ul className="pagination-sub-container">
    <PaginationLeftNavigationArrow {...{ currentPage, onPrevious }} />

    <PaginationRange {...{ DOTS, paginationRange, currentPage, onSelect }} />

    <PaginationRightNavigationArrow {...{ currentPage, lastPage, onNext }} />
  </ul>
);
