import { FC, useContext } from "react";

import { TableContext } from "@/components/Table/context";
import { TableNavbarHeaderProps } from "./types";
import { useSearchParams } from "@/hooks/useSearchParams";
import { MemoizedTableNavbarSearchResults } from "./components/memoizedTableNavbarContent";

export const TableNavbarHeader: FC<TableNavbarHeaderProps> = ({ name }) => {
  const { allRecordsCount, isRefreshing } = useContext(TableContext);
  const className: string = "table-navbar__header-container";
  const { searchParams } = useSearchParams();
  const search: string | null = searchParams.get("q");

  return (
    <MemoizedTableNavbarSearchResults
      {...{ allRecordsCount, className, search, name, isRefreshing }}
    />
  );
};
