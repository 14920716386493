import { BiFilterAlt } from "react-icons/bi";

import { TableNavbarHeader } from "./components/TableNavbarHeader/index";
import { TableNavbarInput } from "./components/TableNavbarInput";
import { TableNavbarIconHelper } from "./components/tableNavbarIconHelper/TableNavbarIconHelper";
import { useModal } from "@/components/Table/hooks/useModal";
import { FiltersContainer } from "../FiltersContainer";
import { PermissionsModal } from "@/ReactTables/UserAdministration/template/PermissionsModal";
import { TableNavbarProps } from "./types/tableNavbar";
import { TableNavbarShareButton } from "./components/TableNavbarShareButton";
import { Icon } from "@/components/Icon";
import { TableNavbarAddRecordButton } from "./components/tableNavbarAddRecordButton";
import { TableDownloadButton } from "./components/TableDownloadButton";
import { DatePicker } from "./components/DatePicker";

import "./style.scss";

export const TableNavbar = ({
  searchable,
  onAddRecord,
  tableOptions,
  name,
  tooltipHelper,
}: TableNavbarProps): JSX.Element => {
  const { modal, handleOpenModal } = useModal(PermissionsModal);
  const { tableExportOptions, tableFilterOptions, tableDateRangeOptions } =
    tableOptions;

  return (
    <div className="table-navbar">
      <TableNavbarHeader {...{ name }} />
      <div className="table-navbar__control-bar">
        <div className="table-navbar__filters">
          <TableNavbarAddRecordButton {...{ onAddRecord }} />
          <DatePicker {...{ tableDateRangeOptions }} />
          <TableNavbarShareButton />
          <TableDownloadButton {...{ tableExportOptions }} />
          <FiltersContainer
            {...{
              trigger: (
                <Icon icon={BiFilterAlt} color="neutral" variant="link" />
              ),
              tableFilterOptions,
            }}
          />
          <TableNavbarIconHelper
            {...{ tooltipHelper, onOpenModal: handleOpenModal }}
          />
        </div>
        {modal}
        <TableNavbarInput {...{ searchable }} />
      </div>
    </div>
  );
};
