import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { BlockedFacilitiesType } from "./types";
import { UserDetails } from "@root/src/components/Table/templates/UserDetails";

export const NetworkBlockedFacilities: FC = () => {
  const url: string = getURL("api:blocked-facilities-list", {
    parent_lookup_pk: "current",
  });

  return (
    <Table<BlockedFacilitiesType>
      id={"facility_id"}
      name="Blocked Facilities"
      searchable
      tooltipHelper={{
        variant: "i",
        message:
          "Once you block a facility, you can no longer transact with each other or view each other's Open Order or Package postings",
      }}
      headers={[
        { title: "FACILITY", key: "name" },
        { title: "TYPE", key: "facility_type" },
        { title: "LOCATION", key: "location" },
      ]}
      expandableRowContent={{
        component: UserDetails,
        props: {
          expandableRow: {
            id: "user_id",
            primaryMessage: "${primary_contact.full_name}",
            secondaryMessage: "${primary_contact.role} at ${name}.",
            options: {
              Email: ["primary_contact", "email"],
              Phone: ["primary_contact", "formatted_office_number"],
              Mobile: ["primary_contact", "formatted_cell_number"],
            },
          },
        },
      }}
      url={url}
      actions={["view_profile", "submit_recall", "submit_disposition_form"]}
    />
  );
};
