import useSWR from "swr";

import { UserType } from "./types";
import { getURL } from "@/utils/getURL";
import { axiosRequest } from "../../hooks/axiosRequest";
import { USER_DEFAULT } from "./constants";

/**
 * Hook that returns the current login user metadata
 *
 * @returns {UserType} The current user
 */
export const useGetCurrentUser = (): UserType => {
  const url: string = getURL("api:internal-users-list") + "current/";
  const { data: response, isLoading } = useSWR(url, () =>
    axiosRequest({ url })
  );

  const user = response?.data;
  if (isLoading) return USER_DEFAULT;
  return user;
};
