import { RxCross2 } from "react-icons/rx";
import { AiOutlinePlus } from "react-icons/ai";

import { Badge } from "@/components/Badge";
import { FiltersContainer } from "../../../FiltersContainer";
import { TableFiltersLabelGeneratorProps } from "./types";

export const TableFiltersLabelGenerator = ({
  filters: activeFilters,
  removeFilter,
  tableFilterOptions,
}: TableFiltersLabelGeneratorProps) => (
  <div className="filters">
    <div className="options">
      <div className="header">Filters:</div>
      {activeFilters.map(({ label, title }) => {
        const key = tableFilterOptions.find(
          (filterOption) => filterOption.label === label
        )?.key;

        if (!key)
          throw new Error(
            "The following filter to be removed does not exist in the tableFilterOptions array."
          );

        return (
          <Badge
            key={title}
            label={`${label}: ${title}`}
            size={"medium"}
            color={"primary"}
            variant={"secondary"}
            className={"active-filter"}
            rightIcon={RxCross2}
            onClick={() => removeFilter(key)}
          />
        );
      })}

      <FiltersContainer
        trigger={
          <Badge
            label={"Add More Filters"}
            rightIcon={AiOutlinePlus}
            variant="outlined"
            color="primary"
          />
        }
        tableFilterOptions={tableFilterOptions}
      />
    </div>
  </div>
);
