import { IconType } from "react-icons";

import { ComponentColor, ComponentVariant } from "@/types";

export enum IconSize {
  "x-small" = 15,
  small = 20,
  medium = 25,
  large = 30,
}

export type IconProps = {
  icon?: IconType;
  testID?: string;
  variant?: ComponentVariant;
  color?: ComponentColor;
  size?: keyof typeof IconSize;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};
