import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { handleCreateStandingOrder } from "../common/utils/handleCreateStandigOrder";

const url: string = getURL("api:internal-standing-orders-list");
const tableOptionsUrl: string = getURL(
  "api:internal-standing-orders-schema-options"
);

export const StandingOrders: FC = () => (
  <Table
    id={"id"}
    sortBy="-next_order"
    onAddRecord={handleCreateStandingOrder}
    name="All Standing Orders"
    calendarConfig={{
      allowedFutureDates: ["next_order"],
    }}
    options={{ url: tableOptionsUrl }}
    searchable
    headers={[
      { title: "#", key: "id" },
      {
        title: "ITEMS",
        properties: { notSortable: true },
        key: "component_string",
      },
      { title: "BUYER", key: "buyer" },
      { title: "SELLER", key: "seller" },
      { title: "REPEATS", key: "frequency" },
      { title: "DAY", key: "day" },
      { title: "NEXT ORDER", key: "next_order", type: "date" },
      { title: "FINAL ORDER", key: "final_order_date", type: "date" },
      {
        title: "STATUS",
        key: "state",
        type: "badge",
        properties: {
          badgeColor: {
            "running": "success",
            "paused": "neutral",
            "canceled": "destructive",
            "expired": "destructive",
          },
        },
      },
    ]}
    actions={["standing_order_activity", "edit_standing_order"]}
    url={url}
  />
);
