import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { BlockIcon } from "../assets";

const url: string = getURL("api:internal-open-orders-list");
const tableOptionsUrl: string = getURL(
  "api:internal-open-orders-schema-options"
);

export const OpenOrders: FC = () => (
  <Table
    id={"id"}
    sortBy="-last_modified"
    name="All Open Orders"
    options={{ url: tableOptionsUrl }}
    searchable
    headers={[
      { title: "#", key: "id" },
      { title: "LAST MODIFIED", key: "last_modified", type: "date" },
      { title: "BUYER", key: "buyer" },
      {
        title: "ITEMS",
        properties: { notSortable: true },
        key: "component_string",
      },
      { title: "LISTED FOR", key: "expire_after" },
      { title: "SHARED WITH", key: "shared_with" },
      { title: "AUDIENCE", key: "audience" },
      {
        title: "STATUS",
        key: "state",
        type: "badge",
        properties: {
          badgeColor: {
            "expired": "destructive",
            "open": "primary",
            "fulfilled": "success",
          },
        },
      },
      {
        title: "BLOCKED",
        key: "has_block",
        type: "boolean",

        properties: {
          booleanType: {
            true: <BlockIcon />,
          },
        },
      },
    ]}
    url={url}
  />
);
