import { Button } from "@/components/Button";
import { TableProps } from "@/components/Table/types";
import { AiOutlinePlus } from "react-icons/ai";

export const TableNavbarAddRecordButton = ({
  onAddRecord,
}: Pick<TableProps, "onAddRecord">) => {
  if (!onAddRecord) return null;

  return (
    <Button
      testID="add-record-button"
      leftIcon={AiOutlinePlus}
      label="New Order"
      size={"small"}
      color={"primary"}
      variant={"filled"}
      className={"new-button"}
      onClick={onAddRecord}
    />
  );
};
