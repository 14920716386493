import { useContext, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import { Button } from "@/components/Button";
import { ModalTemplateProps } from "@/components/Table/hooks/useModal/types";
import { CheckBox } from "@/components/CheckBox";
import { Alert } from "@/components/Alert";
import { updatePermissions } from "./utils/updatePermissions";
import { TableContext } from "@/components/Table/context";
import { useGetUserPermissions } from "./api/getUserPermissions";
import { UserPermissions } from "./api/getUserPermissions/types";
import { updateUserPermissions } from "./api/updateUserPermissions";

export const EditUserPermissionsModal = <T extends Record<string, any>>({
  record: { first_name, last_name, permission_groups, user_id },
  onCloseModal,
}: ModalTemplateProps<T>) => {
  const { mutateTable } = useContext(TableContext);
  const userPermissions = useGetUserPermissions();

  const [permissions, setPermissions] =
    useState<UserPermissions[]>(permission_groups);
  const isPermissionsEmpty: boolean = !permissions.length;

  const isPermissionInsideList = (userPermission: UserPermissions) =>
    permissions.map((permission) => permission.id).includes(userPermission.id);

  return (
    <div className="modal-container">
      {isPermissionsEmpty ? (
        <Alert
          icon={AiOutlineExclamationCircle}
          header="Error"
          description={"At least one permission is required"}
          color={"destructive"}
        />
      ) : null}
      <h4 className="header">Edit User Permissions</h4>
      <br />
      <p>
        You are editing the user permissions for{" "}
        <strong>
          {first_name} {last_name}
        </strong>
        . Please select the permissions applicable. User may belong to more than
        1 permission group.
      </p>
      <br />
      <div className="bullets-container">
        {userPermissions?.map((userPermission) => (
          <CheckBox
            key={userPermission.id}
            checked={isPermissionInsideList(userPermission)}
            onClick={() => updatePermissions(userPermission, setPermissions)}
            label={userPermission.name}
          />
        ))}
      </div>
      <div className="buttons-container">
        <Button
          onClick={onCloseModal}
          size={"medium"}
          color={"primary"}
          variant={"link"}
          label={"Cancel"}
        />
        <Button
          onClick={() =>
            updateUserPermissions({
              user_id,
              permissions,
              onCloseModal,
              mutateTable,
            })
          }
          size={"medium"}
          color={"primary"}
          variant={"filled"}
          label={"Save Changes"}
          disabled={isPermissionsEmpty}
        />
      </div>
    </div>
  );
};
