import { useRef, useState, useEffect } from "react";

import { UseOutsideClickProps } from "./types";

/**
 * Custom hook that detects clicks outside of a given element.
 * This hook is useful for closing dropdowns, modals, or any component
 * that needs to be dismissed when clicking outside of it.
 *
 * @param {UseOutsideClickProps} props - Optional configuration object.
 * @param {Function} [props.onClickOutsideAction] - Callback function that is executed when a click is detected outside of the referenced element.
 * @param {boolean} [props.detectClickInside] - If `true`, the click inside the element will also update the `clickedOutside` state to `false`.
 *
 * @returns {Object} An object containing:
 * - `ref`: A reference to be attached to the monitored element.
 * - `clickedOutside`: Boolean state indicating if a click was detected outside.
 * - `clickedOutsideHandler`: Function to manually set the `clickedOutside` state.
 * - `clickedOutsideToggle`: Function to toggle the `clickedOutside` state.
 */
export const useOutsideClick = <T = HTMLDivElement>({
  onClickOutsideAction,
  detectClickInside,
}: UseOutsideClickProps = {}) => {
  const ref = useRef<T | any>(null);
  const [clickedOutside, setClickedOutside] = useState<boolean>(false);

  const clickedOutsideToggle = () => setClickedOutside(!clickedOutside);
  const clickedOutsideHandler = (value: boolean) => setClickedOutside(value);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setTimeout(() => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setClickedOutside(false);
          return onClickOutsideAction?.();
        }

        if (ref.current.contains(event.target) && detectClickInside)
          setClickedOutside(false);
      }, 0);
    };

    clickedOutside
      ? document.addEventListener("click", handleClickOutside)
      : document.removeEventListener("click", handleClickOutside);

    // Cleanup function to remove the event listener when the component unmounts or `clickedOutside` changes
    return () => document.removeEventListener("click", handleClickOutside);
  }, [clickedOutside, onClickOutsideAction, detectClickInside]);

  return {
    ref,
    clickedOutside,
    clickedOutsideHandler,
    clickedOutsideToggle,
  };
};
