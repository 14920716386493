import React, { useContext, useMemo } from "react";

import { useSearchParams } from "@/hooks/useSearchParams";
import { useFocus } from "../useFocus";
import { TableContext } from "@/components/Table/context";
import { debounce } from "@/utils/debounce";

export const useTableNavbarInputLogic = () => {
  const { searchParams } = useSearchParams();
  const [inputRef, setInputFocus] = useFocus();
  const { updateSearchParams } = useContext(TableContext);

  const firstPage: number = 1;
  const searchQueryStringParam: string = "q"; //TableQuery string paramater that represent the 'search' inside the table
  const debounceTime: number = 500; //Debounce time in ms when searching
  const input = searchParams.get("q") || "";

  const onClick = () => {
    inputRef.current.value = "";
    updateSearchParams?.("page", firstPage.toString());
    setInputFocus();
    updateSearchParams?.("q", "");
  };

  const debounceOnChangeHandler = useMemo(() => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      updateSearchParams?.("page", firstPage.toString());
      updateSearchParams?.(searchQueryStringParam, value);
    };

    return debounce(onChange, debounceTime);
  }, [updateSearchParams]);

  return {
    inputRef,
    debounceOnChangeHandler,
    onClick,
    input,
  };
};
