import { useContext, useState } from "react";

import { useSearchParams } from "@/hooks/useSearchParams";
import { TableContext } from "@/components/Table/context";
import { DateRange } from "@/components/Table/hooks/useTableLogic/types";
import { formatDate } from "@root/src/components/Table/api/useFetch/utils/buildFetchURL/utils/formatDate";
import {
  DEFAULT_DATE_PICKER_DATE_FORMAT,
  PRIMARY_300_COLOR,
} from "../../DatePicker/constants";

export const useCustomDatePickerInputLogic = (value?: string) => {
  const [input, setInput] = useState<string | undefined>("");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const { searchParams } = useSearchParams();
  const { updateSearchParams } = useContext(TableContext);

  const handleUpdateDateRange = ({
    startDate,
    endDate,
  }: Partial<DateRange>) => {
    updateSearchParams?.("start_date", formatDate(startDate));
    updateSearchParams?.("end_date", formatDate(endDate));
  };

  const endDate: boolean = searchParams.has("end_date");

  const handleInvalidState = (value: boolean) => setIsInvalid(value);
  const handleInputState = (value: string) => setInput(value);

  const mask: string = `${DEFAULT_DATE_PICKER_DATE_FORMAT} — ${DEFAULT_DATE_PICKER_DATE_FORMAT}`;

  const dateRangeFromCalendar: string | undefined =
    value?.replace("-", "—") || undefined;

  const dateValue: string | undefined = dateRangeFromCalendar
    ? endDate
      ? dateRangeFromCalendar
      : dateRangeFromCalendar + ` ${DEFAULT_DATE_PICKER_DATE_FORMAT}`
    : undefined;

  const inputStyle = {
    ...(((input && input !== mask) || (dateValue && input !== mask)) && {
      color: PRIMARY_300_COLOR,
    }),
  };

  return {
    input,
    dateValue,
    mask,
    inputStyle,
    handleInputState,
    handleUpdateDateRange,
    handleInvalidState,
    isInvalid,
  };
};
