import { BiChevronDown } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

import { Icon } from "@/components/Icon";
import { DropdownSingleSelectSearchableIconProps } from "./types";

import "./scss/index.scss";

export const DropdownSingleSelectSearchableIcon = ({
  inputValue,
  resetDropdownContent,
  handleFocusInput,
}: DropdownSingleSelectSearchableIconProps) =>
  inputValue ? (
    <Icon
      testID="cross-icon"
      icon={RxCross2}
      color="neutral"
      variant={"link"}
      onClick={resetDropdownContent}
      className="dropdown-single-select-searchable-icon"
    />
  ) : (
    <Icon
      className="dropdown-single-select-searchable-icon"
      testID="down-arrow"
      icon={BiChevronDown}
      onClick={handleFocusInput}
      variant={"link"}
      color="neutral"
    />
  );
