import { RenderURLType } from "./types";

export const RenderURL = ({ value, properties, record }: RenderURLType) => {
  const newTab = { target: "_blank", rel: "noopener noreferrer" };
  const {
    className: getClassName,
    onRender,
    href,
    newWindow,
  } = properties ?? {};

  const isString = (value: unknown): value is string =>
    typeof value === "string";

  const className =
    typeof getClassName === "string"
      ? getClassName
      : getClassName?.(value) || "";

  const url = isString(href) ? href : href?.(record);
  const title = isString(value) ? value : value.title;
  const cellValue = onRender?.(title) ?? title;

  if (!cellValue) return null;

  return (
    <a className={className} {...(newWindow && newTab)} href={url}>
      {cellValue}
    </a>
  );
};
