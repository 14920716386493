import { toast } from "react-toastify";
import { useContext } from "react";

import { TOAST_PROPS } from "@root/src/components/Table/components/TableNavbar/api/downloadTableData/constants";
import { TableContext } from "@/components/Table/context";
import { getURL } from "@/utils/getURL";
import { axiosRequest } from "@/hooks/axiosRequest";

export const usePostOrderAction = (url: string) => {
  const { mutateTable } = useContext(TableContext);
  const orderURL = url;

  return async (data: { reason: number; notes: string }, recordID: number) => {
    const url: string = getURL(orderURL, {
      pk: recordID.toString(),
    });

    try {
      const {
        data: { detail },
        status,
      } = await axiosRequest({ url, data, method: "post" });

      if (status !== 200) return toast.error(detail, TOAST_PROPS);

      toast.success(detail, TOAST_PROPS);
      mutateTable?.();
    } catch (error: any) {
      toast.error(
        error.data.detail || "Action failed, please try again later",
        TOAST_PROPS
      );
    }
  };
};
