import { ModalTemplateProps } from "@/components/Table/hooks/useModal/types";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { OrderActionModalProps } from "./types";
import { useSelectedOption } from "./hooks/useSelectedOption";
import { OrderHeader } from "./components/OrderHeader";
import { OrderDispositionForm } from "./components/OrderDispositionForm";
import { OrderTextArea } from "./components/OrderTextArea";
import { OrderButtons } from "./components/OrderButtons";
import { usePostOrderAction } from "../../../../api/usePostOrderAction";
import { debounce } from "@/utils/debounce";
import { useGetOptions } from "../../../../api/useGetOptions";

import "./scss/index.scss";

export const OrderActionModal = ({
  recordID: orderID,
  hasDispositionForm,
  header,
  url,
  ...props
}: ModalTemplateProps<OrderActionModalProps>) => {
  const options = useGetOptions(url, orderID);
  const handleOnClick = usePostOrderAction(url);

  const { selectedOption, handleSelectedOption } = useSelectedOption();
  const lastOption: number | undefined = options.at(-1)?.value;
  const isMultiReason: boolean = selectedOption.reason === lastOption;

  return (
    <div className="orders-actions-modal-container">
      <OrderHeader {...{ header, orderID }} />
      <DropdownSingleSelect<number>
        options={options}
        label="Reason"
        selectedOption={selectedOption.reason as number}
        size="large"
        onClick={({ value }) => handleSelectedOption("reason", value as number)}
      />
      <OrderDispositionForm {...{ hasDispositionForm }} />
      <OrderTextArea
        {...{
          isMultiReason,
          handleSelectedOption: debounce(handleSelectedOption, 500),
        }}
      />
      <OrderButtons
        {...{
          ...props,
          onClick: handleOnClick,
          isMultiReason,
          orderID,
          selectedOption,
        }}
      />
    </div>
  );
};
