import { useModal } from "@/components/Table/hooks/useModal";
import { DropdownMenuItem } from "../DropdownMenuItem";
import { DropdownModalItemProps } from "./types";

export const DropdownModalItem = ({
  modalTemplate,
  modalProps,
  title,
  className,
  rightAlignment,
  icon,
}: DropdownModalItemProps) => {
  const { modal, handleOpenModal } = useModal(modalTemplate, modalProps);

  return (
    <>
      <DropdownMenuItem
        {...{
          title,
          icon,
          className,
          rightAlignment,
          onClick: handleOpenModal,
          value: "",
        }}
      />
      {modal}
    </>
  );
};
