import { FiHelpCircle } from "react-icons/fi";
import { MdInfoOutline } from "react-icons/md";

import { Icon } from "@/components/Icon";
import { GetNavbarIconProps } from "./types";

export const getNavbarIcon: GetNavbarIconProps = {
  "?": () => (
    <Icon
      {...{
        testID: "tooltip-question",
        icon: FiHelpCircle,
        size: "small",
        color: "neutral",
        variant: "link",
      }}
    />
  ),
  "i": () => (
    <Icon
      testID={"tooltip-info"}
      icon={MdInfoOutline}
      size={"small"}
      color={"neutral"}
      variant={"link"}
    />
  ),
};
