import { handleOnClick } from "./utils/handleOnClick";
import { BadgeLogic, BadgeLogicProps } from "./types";
import { getClassName } from "@root/src/utils/getClassName";

export const badgeLogic = ({
  size,
  variant,
  color,
  disabled,
  className: appendedClass,
  onClick: callback,
  label,
}: BadgeLogicProps): BadgeLogic => {
  const className = getClassName(
    `badge-container ${size} ${variant}-${color} ${
      callback ? "clickable" : "not-clickable"
    }`,
    {
      appendedClass,
      disabled,
    }
  );

  const type = callback ? "button" : "div";

  const onClick = handleOnClick<HTMLDivElement | HTMLButtonElement>(
    label,
    callback
  );

  return {
    className,
    onClick,
    type,
  };
};
