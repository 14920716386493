import useSWR from "swr";

import { axiosRequest } from "@/hooks/axiosRequest";
import { RecipientKeys, MailerRecipientsMap } from "../../../../../../types";
import { UseGetMailerProps } from "./types";

export const useGetMailer = <T extends RecipientKeys>({
  url,
  inputValue,
  getSuggestionsFilter,
  records,
}: UseGetMailerProps<T>): MailerRecipientsMap[T][] => {
  const { data: response } = useSWR(
    [url, inputValue],
    ([url]) =>
      axiosRequest<any, { results: MailerRecipientsMap[T][] }>({
        url,
        params: {
          search: inputValue,
        },
      }),
    { keepPreviousData: true }
  );

  const results = response?.data.results;

  if (!results) return [];

  const filteredRecords = results.filter((result) =>
    getSuggestionsFilter(result, records)
  );

  return filteredRecords;
};
