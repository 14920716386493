import { FC } from "react";
import { MotionProps, motion } from "motion/react";

import { IconProps, IconSize } from "./types";

import "./scss/index.scss";

export const Icon: FC<IconProps> = ({
  icon: Icon,
  testID = "icon",
  color = "primary",
  size = "medium",
  disabled,
  variant = "filled",
  onClick,
  className = "",
}) => {
  const containerClass: string = `icon-container ${color} ${variant} ${size} ${className} ${
    disabled ? "disabled" : ""
  } `;
  const iconClassName: string = `icon ${disabled ? "disabled" : ""} `;
  const iconSize: number = IconSize[size];
  const Container = onClick ? motion.button : motion.div;
  const animations: MotionProps = onClick
    ? { whileTap: { scale: 0.9 }, whileHover: { scale: 1.1 } }
    : {};

  if (!Icon) return null;

  return (
    <Container
      {...animations}
      data-testid="icon-container"
      onClick={onClick}
      className={containerClass}
    >
      <Icon data-testid={testID} size={iconSize} className={iconClassName} />
    </Container>
  );
};
