import { FC } from "react";
import { PiDotsThreeBold } from "react-icons/pi";

import { Icon } from "@/components/Icon";
import { TableRowActionsProps } from "./types";
import { GenerateActions } from "./components/GenerateActions";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import "./scss/index.scss";

export const TableRowActions: FC<TableRowActionsProps> = ({
  actions,
  record,
}) => {
  const { ref, clickedOutside, clickedOutsideToggle } = useOutsideClick();

  return (
    <tr className="table-row__actions-container">
      <td className="table-row__actions-container__table-cell">
        <div className="table-row__actions-container__table-cell-sub" ref={ref}>
          <Icon
            onClick={clickedOutsideToggle}
            icon={PiDotsThreeBold}
            color={"neutral"}
            size={"large"}
            variant={"link"}
          />
          <GenerateActions
            {...{
              actions,
              record,
              isOpen: clickedOutside,
              toggle: clickedOutsideToggle,
            }}
          />
        </div>
      </td>
    </tr>
  );
};
