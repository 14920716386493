import { Badge } from "@/components/Badge";
import { BadgeSize, BadgeVariant } from "@/components/Badge/types";
import { RenderBadgeProps } from "./types";
import { ComponentColor } from "@/types";

export const renderBadge = ({
  value,
  properties,
}: RenderBadgeProps): JSX.Element | null => {
  if (!value) return null;

  const formattedValue: string = value?.toLowerCase().split(" ").join("-");
  const { badgeColor, badgeVariant, badgeSize } = properties ?? {};

  const color: ComponentColor | undefined = badgeColor?.[formattedValue];
  const size: BadgeSize | undefined = badgeSize?.[formattedValue];
  const variant: BadgeVariant | undefined = badgeVariant?.[formattedValue];

  return (
    <Badge
      label={value}
      size={size || "small"}
      color={color || "neutral"}
      variant={variant || "secondary"}
    />
  );
};
