import { useState } from "react";

import { UseVisibility, UseVisibilityProps } from "./types";

/**
 * A custom hook to manage the visibility state of a component.
 *
 * @param {UseVisibilityProps} [props] - Optional props to configure the hook.
 * @param {boolean} [props.defaultValue=false] - Initial visibility state.
 *
 * @returns {UseVisibility} - An object containing:
 *   - `visible`: A boolean indicating whether the element is visible.
 *   - `handleVisibility`: A function to toggle or explicitly set the visibility.
 *
 * @example
 * // Default usage (hidden initially)
 * const { visible, handleVisibility } = useVisibility();
 *
 * // Usage with an initial value
 * const { visible, handleVisibility } = useVisibility({ defaultValue: true });
 *
 * // Toggle visibility
 * handleVisibility();
 *
 * // Explicitly set visibility
 * handleVisibility(true); // Show
 * handleVisibility(false); // Hide
 */
export const useVisibility = ({
  defaultValue = false,
}: UseVisibilityProps = {}): UseVisibility => {
  const [visible, setVisible] = useState<boolean>(defaultValue);

  const handleVisibility = (value?: boolean) => {
    if (typeof value !== "boolean")
      return setVisible((prevValue: boolean) => !prevValue);

    setVisible(value);
  };

  return { visible, handleVisibility };
};
