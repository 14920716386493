import { BlockListModal } from "../../modal/BlockListModal";
import { useInternalBlockedFacilities } from "../../modal/BlockListModal/hooks/useInternalBlockedFacilities";
import { InternalBlockedListProps } from "./types";

export const InternalBlockedList = ({
  facility,
  onCloseModal,
}: InternalBlockedListProps) => {
  const id = facility.id;
  const { blocked_by } = useInternalBlockedFacilities(id) ?? {};

  return (
    <BlockListModal
      blockFacilities={blocked_by}
      currentFacility={facility}
      onCloseModal={onCloseModal}
      variant="blocking"
    />
  );
};
