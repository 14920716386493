import { useDropdownSingleSelectSearchableLogic } from "./hooks/useDropdownSingleSelectSearchableLogic";
import { DropdownSingleSelectSearchableIcon } from "./components/DropdownSingleSelectSearchableIcon";
import { FilteredDropdownMenu } from "./components/FilteredDropdownMenu";
import { DropdownMenuVariants } from "../DropdownMenu/types";
import { DropdownLabel } from "../DropdownMenu/utils/getDropdownMenuVariant/components/DropdownMenuItem/components/DropdownMenuLabel";
import { DropDownSingleSelectSearchableProps } from "./types";

import "./scss/index.scss";

export const DropdownSingleSelectSearchable = <
  Value = string,
  Variant extends DropdownMenuVariants = undefined,
>({
  options,
  selectedOption,
  label,
  loading,
  placeholder,
  onClick,
  ...dropdownMenuProps
}: DropDownSingleSelectSearchableProps<Value, Variant>) => {
  const {
    ref,
    clickedOutside,
    resetDropdownContent,
    filteredOptions,
    title,
    onFocus,
    handleFocusInput,
    onChange,
    enhancedOnClick,
  } = useDropdownSingleSelectSearchableLogic<Value, Variant>({
    options,
    loading,
    selectedOption,
    placeholder,
    onClick,
  });

  return (
    <div>
      <DropdownLabel label={label} />
      <div className="single-select-searchable-dropdown">
        <DropdownSingleSelectSearchableIcon
          {...{
            resetDropdownContent,
            inputValue: ref.current?.value,
            handleFocusInput,
          }}
        />
        <input
          ref={ref}
          className="input"
          data-testid="input"
          type={"text"}
          placeholder={title.toString()}
          onChange={({ target: { value } }) => onChange(value)}
          onFocus={onFocus}
        />
        <FilteredDropdownMenu<Value, Variant>
          {...{
            open: clickedOutside,
            options: filteredOptions,
            selectedOption,
            onClick: enhancedOnClick,
            ...dropdownMenuProps,
          }}
        />
      </div>
    </div>
  );
};
