import useSWR from "swr";

import { axiosRequest } from "@/hooks/axiosRequest";
import { getURL } from "@/utils/getURL";
import { FetchUserPermissions } from "./types";


export const useGetUserPermissions = () => {
  const url: string = getURL("api:groups-list");
  const { data: result } = useSWR(url, (url) =>
    axiosRequest<any, FetchUserPermissions>({ url })
  );
  const { results } = result?.data ?? {};

  return results;
};
