import { InternalBlockingList } from "./components/InternalBlockingList";
import { InternalBlockedList } from "./components/internalBlockedList";
import { BlockListProps } from "./types";

export const blockingList = ({ title, facility }: BlockListProps) => ({
  title,
  variant: "modal",
  modalTemplate: InternalBlockingList,
  modalProps: { facility },
});

export const blockedList = ({ title, facility }: BlockListProps) => ({
  title,
  variant: "modal",
  modalTemplate: InternalBlockedList,
  modalProps: { facility },
});
