import { FC, useContext } from "react";
import { AnimatePresence, motion } from "motion/react";

import { TableHeaderItem } from "./components/TableHeaderItem";
import { TableHeadersProps } from "./types";
import { EmptyHeader } from "./components/EmptyHeader";
import { TableHeadersBulkAction } from "./components/TableHeadersBulkAction";
import { TableContext } from "@/components/Table/context";
import { GENERIC_FADE_ANIMATION } from "@/constants";

import "./scss/index.scss";

export const TableHeaders: FC<TableHeadersProps> = ({
  headers,
  resetExpandState,
}) => {
  const { expandableRow, isTableLoading } = useContext(TableContext);
  const isDropDown: boolean = Boolean(expandableRow);
  const emptyHeaderWidth: string = isTableLoading
    ? "1.5"
    : isDropDown
    ? "36px"
    : "0";

  return (
    <AnimatePresence>
      <motion.thead
        {...GENERIC_FADE_ANIMATION}
        className="table-headers-container"
      >
        <motion.tr className="table-row">
          <TableHeadersBulkAction />
          <EmptyHeader width={emptyHeaderWidth} />
          {headers.map((header) => (
            <TableHeaderItem
              key={header.title}
              header={header}
              resetExpandState={resetExpandState}
            />
          ))}
          <EmptyHeader width="60px" />
        </motion.tr>
      </motion.thead>
    </AnimatePresence>
  );
};
