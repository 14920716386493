import { MailerRecipientsMap, RecipientKeys } from "../../types/index";

export const isUserMailerArray = (
  records: MailerRecipientsMap[RecipientKeys][]
): records is MailerRecipientsMap["user"][] => {
  for (const record of records) {
    if (!("email" in record)) return false;
  }
  return true;
};
