import { getURL } from "@/utils/getURL";
import { UserMailerDetails } from "../../../../../../types";
import { MailerUserDetails } from "../../../../../../components/mailerInput/components/UserDetails/index";
import { MailerRecipientDetailsMap } from "../../../../types";

export const getUserLogic = (): MailerRecipientDetailsMap["user"] => ({
  type: "user",
  bccContent: (users) =>
    (users as UserMailerDetails[]).map(({ email }) => email).join(", "),
  suggestionsUrl: getURL("api:internal-users-list"),
  removeRecipient: (
    recordToBeRemoved: UserMailerDetails,
    activeRecords: UserMailerDetails[]
  ) => {
    return activeRecords.filter(
      (activeRecord) => activeRecord.email !== recordToBeRemoved.email
    );
  },
  component: (record: UserMailerDetails) => (
    <MailerUserDetails record={record} />
  ),
  getSuggestionsFilter: (
    record: UserMailerDetails,
    activeRecords: UserMailerDetails[]
  ) =>
    record.is_active &&
    !activeRecords.some(
      (activeRecord) => activeRecord.user_id === record.user_id
    ),
  getBadgeLabel: (record: UserMailerDetails) =>
    `${record.full_name} <${record.email}>`,
  badgeColor: "primary",
  badgeVariant: "secondary",
});
