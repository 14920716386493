import { useRef, useState } from "react";

import { debounce } from "@/utils/debounce";
import { ItemsContainerItem, ItemsContainerProps } from "../../types";
import { useSelectedItems } from "./hooks/useSelecteItems";
import { getFilteredSelectedItems } from "./utils/getFilteredSelectedItems";
import { handleSubmit } from "./utils/handleSubmit";

export const useCodesContainerLogic = <
  Item extends ItemsContainerItem,
  FilterBy extends keyof Item
>({
  items = [],
  onClick,
  getItemLabel,
  filterOptions,
  filterBy: filterByProp,
}: Pick<
  ItemsContainerProps<Item, FilterBy>,
  "items" | "onClick" | "getItemLabel" | "filterBy" | "filterOptions"
>) => {
  //Input ref
  const inputRef = useRef<HTMLInputElement>(null);
  const resetInputValue = () => {
    if (!inputRef.current) return;
    inputRef.current.value = "";
  };

  //Current filter
  const [filterBy, setFilterBy] = useState<Item[FilterBy] | "All">("All");
  const handleFilterBy = (value: Item[FilterBy] | "All") => setFilterBy(value);
  const handleResetFilterBy = () => setFilterBy("All");

  const {
    handleAddItem,
    handleSelectItems,
    handleResetSelectedCodes,
    selectedItems,
    areAllItemsSelected,
  } = useSelectedItems<Item, FilterBy>({ items });

  // Search hook
  const [query, setQuery] = useState<string>("");
  const handleQueryChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(value);
  };
  const handleResetQuery = () => setQuery("");
  const debouncedQueryChange = debounce(handleQueryChange, 500);

  //Filtered items to render
  const filteredSelectedItems = getFilteredSelectedItems<Item, FilterBy>({
    items,
    filterBy,
    getItemLabel,
    query,
    filterByProp,
  });

  //Handle on submit button
  const handleOnSubmit = handleSubmit<Item>({
    onClick,
    selectedItems,
    resetStates: () => {
      handleResetSelectedCodes();
      handleResetQuery();
      resetInputValue();
      handleResetFilterBy();
    },
  });

  //Filter options
  const dropdownFilterOptions = [...filterOptions, "All"].map((option) => ({
    title: option,
    value: option as Item[FilterBy],
  }));

  //Check if all items are selected
  const isChecked = areAllItemsSelected && items?.length !== 0;

  //Handler for selecting all items
  const handleSelectAllItems = (checked: boolean) => {
    if (!checked) return handleSelectItems([]);
    handleSelectItems(filteredSelectedItems);
  };

  //Has any items or selected items
  const hasNoItems = !items || !items.length;
  const hasNoSelectedItems = !selectedItems || !selectedItems.length;
  const isDisabled = hasNoItems || hasNoSelectedItems;

  return {
    filteredItems: filteredSelectedItems,
    areAllItemsSelected,
    handleAddItem,
    dropdownFilterOptions,
    isChecked,
    handleQueryChange: debouncedQueryChange,
    isDisabled,
    handleSelectAllItems,
    filteredSelectedItems,
    handleFilterBy,
    selectedItems,
    handleOnSubmit,
    handleSelectItems,
    inputRef,
    filterBy,
  };
};
