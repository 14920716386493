import { MAX_FILTER_OPTIONS_COUNT } from "../../constants";
import { DropdownSingleSelectSearchable } from "@/components/DropdownSingleSelectSearchable";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { SingleOrSearchableDropdownProps } from "./types";

export const SingleOrSearchableDropdown = ({
  data,
  subHeaderOptions,
  dataHandler,
}: SingleOrSearchableDropdownProps) => {
  const { title, label } = data;
  if (!label) return null;

  const subHeaderOptionsCount = subHeaderOptions.length;
  const isOverMaxFilterCount = subHeaderOptionsCount > MAX_FILTER_OPTIONS_COUNT;

  if (isOverMaxFilterCount)
    return (
      <DropdownSingleSelectSearchable
        key={label}
        selectedOption={title}
        options={subHeaderOptions}
        label={label.toString()}
        onClick={dataHandler}
      />
    );

  return (
    <DropdownSingleSelect
      selectedOption={title}
      options={subHeaderOptions}
      label={label.toString()}
      onClick={dataHandler}
    />
  );
};
