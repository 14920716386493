import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { BlockIcon } from "../assets";

const url: string = getURL("api:internal-packages-list");
const tableOptionsUrl: string = getURL("api:internal-packages-schema-options");

export const Packages: FC = () => (
  <Table
    id={"id"}
    sortBy="-last_modified"
    name="All Packages"
    options={{ url: tableOptionsUrl }}
    searchable
    headers={[
      { title: "#", key: "id" },
      { title: "MODIFIED", key: "last_modified", type: "date" },
      { title: "SELLER", key: "seller" },
      {
        title: "ITEMS",
        properties: { notSortable: true },
        key: "component_string",
      },
      { title: "LISTED FOR", key: "listed_for" },
      { title: "AUDIENCE", key: "audience" },
      { title: "SHARED WITH", key: "shared_with" },
      {
        title: "STATUS",
        key: "pkg_status",
        type: "badge",
        properties: {
          badgeColor: {
            "active": "primary",
            "purchased": "success",
            "archived": "neutral",
            "canceled": "destructive",
            "expired": "destructive",
            "offline": "destructive",
            "declined": "destructive",
          },
        },
      },
      {
        title: "BLOCKED",
        key: "has_block",
        type: "boolean",

        properties: {
          booleanType: {
            true: <BlockIcon />,
          },
        },
      },
    ]}
    url={url}
  />
);
