export const ManageBlockedFacilitiesHeader = () => (
  <header className="manage-blocked-facilities-modal__header">
    <h1 className="manage-blocked-facilities-modal__header-title">
      Manage Blocked Facilities
    </h1>
    <h5 className="manage-blocked-facilities-modal__header-subtitle">
      Once you add a a facility to the blocked list of another, the 2 facilities
      can no longer transact with each other or view each other’s Open Orders
      and/or Packages in the maketplace.
    </h5>
  </header>
);
