import { RiErrorWarningFill } from "react-icons/ri";
import { MdOutlineDownloading } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { InfoTable } from "./components/InfoTable";
import { TableNavbar } from "./components/TableNavbar";
import { BaseTable } from "./components/BaseTable/index";
import { Pagination } from "./components/BaseTable/components/Pagination";
import { TableFilters } from "./components/TableFilters";
import { BulkActionsButtons } from "./components/BulkActionsButtons";
import { TableProps } from "./types";
import { TableContext } from "./context";
import { useTableLogic } from "./hooks/useTableLogic";
import { useCreateToast } from "./hooks/useCreateToast";

import "./scss/index.scss";

/**
 * Renders a dynamic table component with various features like sorting, filtering, bulk actions, pagination, etc.
 *
 * @param {TableProps} props - Component props.
 * @param {string} props.id - Name of the ID property inside the records API response.
 * @param {TableNavbar} [props.navbar] - Conditional Navbar to be render on top of the table.
 * @param {TableFilters} [props.filters] - Options for table filters.
 * @param {TableDropDownProps} [props.expandableRowContent] - Conditional drop down rows to render inside the table.
 * @param {TableHeaders} props.headers - Headers of the table.
 * @param {TableActions} [props.actions] - Conditional actions to render inside the table.
 * @param {string} props.url - URL (API) from which the table will be populated.
 * @param {boolean} [props.addRecord] - Flag to indicate if record addition is allowed.
 * @param {TableBulkActions} [props.bulkActions] - Conditional bulk actions to render inside the table.
 *
 * @returns {JSX.Element} The JSX element, Table component.
 *
 * @example
 * Simple usage:
 * ```
 * <Table<FacilityUserType>
 *  id={"user_id"}
 *  headers={[
 *  { title: "NAME", key: "name" },
 *  { title: "EMAIL", key: "email" },
 *  { title: "FACILITY", key: "facility" },
 *  { title: "ROLE", key: "role" },
 *  ]}
 *  url={facilityURL}
 * />
 * ```
 *
 * @example
 * With navbar:
 * ```
 * <Table<FacilityUserType>
 *  id={"user_id"}
 *  headers={[
 *  { title: "NAME", key: "name" },
 *  { title: "EMAIL", key: "email" },
 *  { title: "FACILITY", key: "facility" },
 *  { title: "ROLE", key: "role" },
 *  ]}
 * navbar={{
 *  navbarHeader: "Users",
 *  navbarTooltip: {
 *   type: "?", // options "?" | "!"
 *   message: "A list of all users in your facility.",
 *  },
 *  input: true,
 * }}
 *  url={facilityURL}
 * />
 * ```
 *
 * @example
 * With drop down:
 * ```
 * <Table<FacilityUserType>
 *  id={"user_id"}
 *  headers={[
 *   { title: "NAME", key: "name" },
 *   { title: "EMAIL", key: "email" },
 *   { title: "FACILITY", key: "facility" },
 *   { title: "ROLE", key: "role" },
 *  ]}
 *  expandableRowContent={{
 *   template: UserDetails,
 *   props: { //props are conditional
 *    expandableRow: {
 *     id: "user_id",
 *     primaryMessage: "$name,",
 *     secondaryMessage: "$role at $facility.",
 *     options: {
 *      Email: "email",
 *      Phone: "formatted_office_number",
 *      Mobile: "formatted_cell_number",
 *     },
 *    },
 *   },
 *  }}
 *  url={facilityURL}
 * />
 *
 * ```
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4293 - Jira Story}
 */
export const Table = <T extends Record<string, unknown>>({
  headers,
  id,
  url,
  name,
  bulkActions,
  searchable = true,
  sortBy,
  onAddRecord,
  options,
  tooltipHelper,
  ...props
}: TableProps) => {
  const {
    getSearchParams,
    checkboxesConfig,
    data,
    expandState,
    pagination,
    mutateTable,
    tableOptions,
  } = useTableLogic<T>({
    url,
    sortBy,
    headers,
    options,
  });

  //Toast notifications for Django messages
  useCreateToast();

  if (data.error)
    return <InfoTable primaryMessage={data.error} icon={RiErrorWarningFill} />;

  if (data.allRecordsCount === undefined)
    return (
      <InfoTable primaryMessage="Loading..." icon={MdOutlineDownloading} />
    );

  return (
    <div className="table-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <TableContext.Provider
        value={{
          ...props,
          ...checkboxesConfig,
          sortBy: sortBy?.startsWith("-") ? sortBy.slice(1) : sortBy,
          bulkActions,
          ...expandState,
          ...data,
          mutateTable,
          allRecordsCount: data.allRecordsCount,
          baseUrl: url,
          updateSearchParams: getSearchParams.updateSearchParams,
          getSearchParams,
          id,
        }}
      >
        <TableNavbar
          {...{
            searchable,
            onAddRecord,
            name,
            tableOptions,
            tooltipHelper,
          }}
        />
        <TableFilters
          {...{ tableFilterOptions: tableOptions.tableFilterOptions }}
        />
        <BulkActionsButtons
          {...{
            bulkActions,
            checkboxes: checkboxesConfig.checkboxes,
            checkboxesHandler: checkboxesConfig.checkboxesHandler,
          }}
        />
        <BaseTable
          {...{
            headers,
            records: data.records,
            isLoading: data.isLoading,
          }}
        />
      </TableContext.Provider>
      <Pagination {...{ ...pagination, totalCount: data.allRecordsCount }} />
    </div>
  );
};
