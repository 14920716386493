import { Todo } from "@/types";
import { NoValue } from "./components/NoValue";
import { RenderArray } from "./components/RenderArray";
import { renderBadge } from "./components/RenderBadge";
import { renderBoolean } from "./components/RenderBoolean";
import { renderDate } from "./components/RenderDate";
import { renderNumber } from "./components/RenderNumber";
import { renderString } from "./components/RenderString";
import { RenderURL } from "./components/RenderURL";
import { RenderButton } from "./components/renderButton";
import { GetValueProps } from "./types";

export const getValue = ({
  record,
  key,
  properties,
  type,
  ...rest
}: GetValueProps<Todo>): JSX.Element | null => {
  //Get key/subKey from array, if key is an array
  const [headerKey, subKey] = Array.isArray(key) ? key : [key, undefined];

  // Retrieve the record value based on key and subKey
  const value = subKey ? record[headerKey]?.[subKey] : record[headerKey];

  //Return null given no value or empty array
  if (value === null || value === undefined) return null;
  if (Array.isArray(value) && value.length === 0) return null;

  // Map component types to their corresponding component functions
  const componentMap: Record<string, any> = {
    string: renderString,
    boolean: renderBoolean,
    number: renderNumber,
    url: RenderURL,
    badge: renderBadge,
    date: renderDate,
    array: RenderArray,
    button: RenderButton,
  };

  const component = componentMap[type || typeof value];
  if (!component) {
    console.warn("No component found for type:", type);
    return <NoValue />;
  }

  return component({ value, headerKey, record, properties, ...rest });
};
