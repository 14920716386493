import { BlockListModalDescriptionProps } from "./types";
import "./scss/index.scss";

export const BlockListModalDescription = ({
  blockListCount = 0,
}: BlockListModalDescriptionProps) => {
  if (!blockListCount || blockListCount === 0) return null;
  const facilityLabel = blockListCount === 1 ? "facility" : "facilities";

  return (
    <p className="block-list-modal__description">
      <span className="block-list-modal__description__span">
        {blockListCount} {facilityLabel}
      </span>
    </p>
  );
};
