import { useContext } from "react";

import { DualListSelector } from "@/components/DualListSelector";
import { ManageBlockedFacilitiesHeader } from "./components/manageBlockedFacilitiesHeader";
import { ManageBlockedFacilitiesFooter } from "./components/manageBlockedFacilitiesFooter";
import { ManageBlockedFacilitiesModalProps } from "./types";
import { InternalFacilitiesTags } from "./hooks/internalFacilitiesTags/types";
import { updateBlockedFacilities } from "./api/updateBlockedFacilities";
import { useManageBlockedFacilitiesModalLogic } from "./hooks/useManageBlockedFacilitiesModalLogic";
import { TableContext } from "@root/src/components/Table/context";

import "./scss/index.scss";

export const ManageBlockedFacilitiesModal = ({
  facility,
  onCloseModal,
}: ManageBlockedFacilitiesModalProps) => {
  const { mutateTable } = useContext(TableContext);
  const { blockingFacilities, uniqueTags, internalFacilitiesTags } =
    useManageBlockedFacilitiesModalLogic({
      facility,
      onCloseModal,
    });

  let selectedFacilities: InternalFacilitiesTags[] = [];

  const handleOnSubmit = async () => {
    onCloseModal();
    await updateBlockedFacilities({
      facilitiesIds: selectedFacilities.map(({ value }) => value),
      facilityId: facility.id,
    });
    mutateTable?.();
  };

  return (
    <div className="manage-blocked-facilities-modal">
      <ManageBlockedFacilitiesHeader />
      <DualListSelector
        itemsToAddContainer={{
          label: "Unblocked Facilities",
          filterBy: "tags",
          getItemLabel: (item) => item.title,
          onClickButtonLabel: "Add to Blocked List",
        }}
        selectedItemsContainer={{
          label: "Blocked Facilities",
          filterBy: "tags",
          getItemLabel: (item) => item.title,
          onClickButtonLabel: "Remove from Blocked List",
        }}
        filterOptions={uniqueTags}
        onChange={(items) => (selectedFacilities = items)}
        initialItems={blockingFacilities}
        items={internalFacilitiesTags}
      />
      <ManageBlockedFacilitiesFooter
        onClose={onCloseModal}
        onSubmit={handleOnSubmit}
      />
    </div>
  );
};
