import { InputProps } from "./types/index";
import { debounce } from "@/utils/debounce";

import "./scss/index.scss";

export const Input = ({
  label,
  disabled,
  value,
  onChange,
  inputKey,
}: InputProps) => {
  const inputClassName: string = `emailer-input ${disabled ? "disabled" : ""}`;
  const divClassName: string = `emailer-input-container ${
    disabled ? "disabled" : ""
  }`;
  const labelClassName: string = `input-label ${disabled ? "disabled" : ""}`;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(event.target.value.trim());

  const debounceOnChange = debounce(handleOnChange, 1000);

  return (
    <div className={divClassName}>
      <label className={labelClassName}>{label}</label>
      <input
        key={inputKey}
        readOnly={disabled}
        defaultValue={value}
        type="text"
        id={label}
        className={inputClassName}
        onChange={debounceOnChange}
      />
    </div>
  );
};
