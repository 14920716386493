import { RenderStringType } from "./types";
import { NoValue } from "../NoValue";

import "./scss/index.scss";

export const renderString = ({ value, properties }: RenderStringType) => {
  if (!value) return <NoValue />;

  const { className: newClass, onRender } = properties ?? {};
  const className: string = `table-cell-string ${newClass ?? ""}`;
  const cellValue = onRender ? onRender(value) : value;

  return <div className={className}>{cellValue}</div>;
};
