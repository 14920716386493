import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { handleCreateStandingOrder } from "../common/utils/handleCreateStandigOrder";

const url: string = getURL("api:internal-standing-orders-active");
const tableOptionsUrl: string = getURL(
  "api:internal-standing-orders-schema-options"
);

export const ActiveStandingOrders: FC = () => {
  return (
    <Table
      id={"facility_id"}
      sortBy="next_order"
      onAddRecord={handleCreateStandingOrder}
      name="Active Standing Orders"
      options={{ url: tableOptionsUrl }}
      calendarConfig={{
        allowedFutureDates: ["next_order"],
      }}
      searchable
      headers={[
        { title: "#", key: "id" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "BUYER", key: "buyer" },
        { title: "SELLER", key: "seller" },
        { title: "REPEATS", key: "frequency" },
        { title: "DAY", key: "day" },
        { title: "NEXT ORDER", key: "next_order", type: "date" },
        { title: "FINAL ORDER", key: "final_order_date", type: "date" },
      ]}
      url={url}
      actions={["standing_order_activity", "edit_standing_order"]}
    />
  );
};
