import { BiDownload } from "react-icons/bi";
import { useContext } from "react";

import { DropdownMenu } from "@/components/DropdownMenu";
import { Icon } from "@/components/Icon";
import { downloadTableData } from "../../api/downloadTableData";
import { TableContext } from "@/components/Table/context";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { TableDownloadButtonProps } from "./types";

import "./scss/index.scss";

export const TableDownloadButton = ({
  tableExportOptions,
}: TableDownloadButtonProps) => {
  const { baseUrl, keys } = useContext(TableContext);
  const { clickedOutside, ref, clickedOutsideToggle } = useOutsideClick();

  if (!tableExportOptions || !tableExportOptions.choices.length) return null;

  const { choices, param_name } = tableExportOptions;

  return (
    <div ref={ref} className="table-navbar-download-button">
      <Icon
        testID="download-button-icon"
        icon={BiDownload}
        onClick={clickedOutsideToggle}
        variant="link"
        color="neutral"
        size="medium"
      />

      {clickedOutside ? (
        <div style={{ position: "relative" }}>
          <DropdownMenu<string>
            header={"Download as..."}
            onClick={({ value: exportFormat }) => {
              clickedOutsideToggle();
              return downloadTableData({
                exportParamName: param_name,
                exportFormat,
                currentApiEndpoint: baseUrl,
                keys,
              });
            }}
            options={choices}
          />
        </div>
      ) : null}
    </div>
  );
};
