import { UseDropdownSingleSelectLogicProps } from "./types";
import { DropdownMenuVariants } from "@/components/DropdownMenu/types";
import { isValidValue } from "./utils/isValidValue";
import { getDropdownTitle } from "@/components/utils/getDropdownTitle";
import { useOutsideClick } from "@/hooks/useOutsideClick";

export const useDropdownSingleSelectLogic = <
  Value = string,
  Variant extends DropdownMenuVariants = undefined,
>({
  options,
  selectedOption,
  placeholder,
  loading,
}: UseDropdownSingleSelectLogicProps<Value, Variant>) => {
  const { ref, clickedOutside, clickedOutsideToggle } = useOutsideClick();
  const thereAreNoOptions: boolean = !options.length;

  const buttonClassName: string = `single-select-dropdown ${
    clickedOutside ? "open" : ""
  }`;

  const focusButtonOnClick = () => ref.current.focus();

  const optionFound = options.find(
    ({ value, title }) =>
      (isValidValue<Value>(value) && value === selectedOption) ||
      title === selectedOption
  );

  const optionFoundValue = optionFound?.title ?? optionFound?.value;

  const title = getDropdownTitle<Value>({
    loading,
    placeholder,
    selectedOption,
    optionFoundValue,
    thereAreNoOptions,
  });

  return {
    ref,
    clickedOutsideToggle,
    focusButtonOnClick,
    clickedOutside,
    buttonClassName,
    optionFoundValue,
    title,
    thereAreNoOptions,
  };
};
