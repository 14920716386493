import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

import { TableHeaderItemIconProps } from "./types";
import { Icon } from "@/components/Icon";

import "./scss/index.scss";

export const TableHeaderItemIcon = ({
  sortBy: currentSortBy,
  headerKey,
}: TableHeaderItemIconProps) => {
  const sortBy: string | undefined = currentSortBy?.replace("-", "");
  const isActive: boolean = sortBy === headerKey;
  const isAscending: boolean = currentSortBy?.[0] !== "-";

  // Up arrow icon if active (clicked) and not ascending
  // Down arrow icon if not active and ascending
  const icon =
    isActive && !isAscending ? MdKeyboardArrowDown : MdOutlineKeyboardArrowUp;

  const className: string = isActive
    ? "table-header__icon--show"
    : "table-header__icon--hide";

  return (
    <Icon
      testID="table-header-item-icon"
      icon={icon}
      className={className}
      variant="link"
      color="neutral"
    />
  );
};
