import { Icon } from "@/components/Icon";
import { AlertInfoIconProps } from "./types";

import "./scss/index.scss";

export const AlertInfoIcon = ({ icon, color }: AlertInfoIconProps) => {
  if (!icon) return null;

  return (
    <Icon
      {...{
        icon,
        colorClassName: "alert-icon",
        color,
        variant: "link",
        testID: "alert-info-icon",
        className: "alert-info-icon",
      }}
    />
  );
};
