import { Button } from "@/components/Button";
import { Permission } from "./components/Permission";
import { PermissionType, PermissionsModalProps } from "./types";
import { useGetPermissions } from "./api/useGetPermissions";

export const PermissionsModal = ({ onCloseModal }: PermissionsModalProps) => {
  const permissions = useGetPermissions();

  return (
    <div className="modal-container">
      <h2 className="header">User Permissions</h2>
      <br />
      {permissions.map(
        ({ name, permissions, id, short_name }: PermissionType) => (
          <Permission key={id} {...{ name, permissions, short_name }} />
        )
      )}
      <br />
      <Button
        className={"right-alignment"}
        onClick={onCloseModal}
        size={"medium"}
        color={"primary"}
        variant={"filled"}
        label={"Close"}
      />
    </div>
  );
};
