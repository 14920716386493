import { InternalBlockedFacilitiesType } from "../../types";

export const getBlockBy = ((
  blockedBy: InternalBlockedFacilitiesType["blocked_by"]
) => {
  const [firstFacility] = blockedBy;
  return firstFacility?.name;
}) as <InternalBlockedFacilitiesType>(
  value: InternalBlockedFacilitiesType
) => string;
