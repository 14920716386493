import { ItemsContainer } from "./components/ItemsContainer";
import { ItemsContainerItem } from "./components/ItemsContainer/types";
import { ProductItemsSelectorProps } from "./types";
import { useDualListSelectedItems } from "./hooks/useDualListSelectedItems";
import "./scss/index.scss";

export const DualListSelector = <
  T extends ItemsContainerItem,
  K extends keyof T
>({
  itemsToAddContainer,
  selectedItemsContainer,
  items,
  initialItems,
  filterOptions,
  onChange,
  className,
}: ProductItemsSelectorProps<T, K>) => {
  const {
    filteredMockItems,
    handleSelectedCode,
    handleRemoveItem,
    currentItems,
  } = useDualListSelectedItems({
    items,
    initialItems,
    onChange,
  });
  const hasSelectedItems = currentItems.length;

  return (
    <div className={`dual-list-selector ${className}`}>
      <ItemsContainer<T, K>
        {...{
          ...itemsToAddContainer,
          items: filteredMockItems,
          onClick: handleSelectedCode,
          filterOptions,
        }}
      />
      <ItemsContainer<T, K>
        {...{
          ...selectedItemsContainer,
          items: currentItems,
          onClick: handleRemoveItem,
          filterOptions,
          disabled: !hasSelectedItems,
        }}
      />
    </div>
  );
};
