import {
  DEFAULT_PAGINATION_CURRENT_PAGE,
  DEFAULT_PAGINATION_PAGE_SIZE,
} from "@/components/Table/constants";
import { BuildFetchURLProps } from "./types";
import { Todo } from "@/types";

export const buildFetchUrl = ({
  url,
  searchParams,
  defaultSortBy,
}: BuildFetchURLProps): string => {
  const page: number =
    Number(searchParams.get("page")) || DEFAULT_PAGINATION_CURRENT_PAGE;
  const pageSize =
    Number(searchParams.get("page_size")) || DEFAULT_PAGINATION_PAGE_SIZE;
  const input: string = searchParams.get("q") || "";
  const sortBy: string = searchParams.get("sort_by") || defaultSortBy;
  const startDate: string | null = searchParams.get("start_date");
  const endDate: string | null = searchParams.get("end_date");
  const dateStatus: string = searchParams.get("date_field") || "";

  const filters = searchParams.get("filters");

  const offset = (page - 1) * pageSize;
  const params = new URLSearchParams();

  params.set("limit", pageSize.toString());
  params.set("offset", offset.toString());
  params.set("search", input);

  // Only set ordering if `sortBy` is provided
  if (sortBy) params.set("ordering", sortBy);

  const activeFilters = filters ? JSON.parse(decodeURIComponent(filters)) : [];

  // Add filters if present
  if (activeFilters && activeFilters.length > 0) {
    activeFilters.forEach(({ key, value }: Todo) => {
      // Convert filter keys and values to lowercase and encode them
      params.set(key.toLowerCase(), value);
    });
  }

  // Add date parameters if applicable
  if (startDate && endDate && dateStatus) {
    params.set(`${dateStatus}_after`, startDate);
    params.set(`${dateStatus}_before`, endDate);
  }

  return `${url}?${params.toString()}`;
};
