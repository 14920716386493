import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";

const url: string = getURL("api:internal-open-orders-direct");
const tableOptionsUrl: string = getURL(
  "api:internal-open-orders-schema-options"
);

export const DirectOpenOrders: FC = () => {
  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      name="Direct Open Orders"
      options={{
        url: tableOptionsUrl,
        filters: {
          filtersToExclude: ["has_block", "is_direct", "expire_after"],
        },
      }}
      searchable
      headers={[
        { title: "#", key: "id" },
        { title: "LAST MODIFIED", key: "last_modified", type: "date" },
        { title: "BUYER", key: "buyer" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "SHARED WITH", key: "shared_with" },
        { title: "SELLER", key: "seller" },
        { title: "AUDIENCE", key: "audience" },
        {
          title: "STATUS",
          key: "state",
          type: "badge",
          properties: {
            badgeColor: {
              "expired": "destructive",
              "open": "primary",
              "fulfilled": "success",
            },
          },
        },
      ]}
      url={url}
    />
  );
};
