import { NavbarSearchResultsProps } from "./types";

export const NavbarSearchResults = ({
  search,
  allRecordsCount,
  name,
}: NavbarSearchResultsProps) => {
  const result: string = allRecordsCount === 1 ? "result" : "results";
  const className: string = `results ${search ? "visible" : ""}`;

  if (!search) return null;

  return (
    <span className={className}>
      <strong>{allRecordsCount}</strong> {result} found for{" "}
      <strong>{search}</strong> {name ? `in ${name}` : ""}
    </span>
  );
};
