import {
  FacilityMailerDetails,
  MailerRecipientsMap,
  RecipientKeys,
  UserMailerDetails,
} from "../../../../types";

export type InitialMailerData = {
  email_addresses?: UserMailerDetails[];
  facility_uuids?: FacilityMailerDetails[];
};

type RecipientDataMap = {
  [Key in RecipientKeys]: (
    records: MailerRecipientsMap[Key][]
  ) => InitialMailerData;
};

export const getInitialMailerData = <Key extends RecipientKeys>(
  activeRecords: MailerRecipientsMap[Key][],
  recipient: Key
) => {
  const recipientDataMap: RecipientDataMap = {
    user: (records) => ({
      email_addresses: records,
    }),
    facility: (records) => ({
      facility_uuids: records,
    }),
  };

  return recipientDataMap[recipient](activeRecords);
};
