import { FC, useContext } from "react";

import { DropdownMenu } from "@/components/DropdownMenu";
import { GenerateActionsProps } from "./types";
import { TableAction } from "@/components/Table/types/TableActions";
import { getAction } from "./utils/useGetAction";
import { TableContext } from "@/components/Table/context";
import { DropdownMenuOption } from "@/components/DropdownMenu/types";

import "./scss/index.scss";

export const GenerateActions: FC<GenerateActionsProps> = ({
  isOpen,
  actions,
  record,
  toggle,
}) => {
  const className: string = ` table-row__actions-container__actions ${
    isOpen ? "active" : undefined
  } drop-shadow-medium `;

  const { id } = useContext(TableContext);

  const options: DropdownMenuOption[] = actions.map((action) => {
    const currentAction: TableAction =
      typeof action === "object" ? action.current : action;

    const config: object | undefined =
      typeof action === "object" ? action.config : undefined;

    return getAction({
      action: currentAction,
      record,
      config,
      id,
    });
  });

  return (
    <div className={className}>
      <DropdownMenu
        {...{
          options,
          onClick: toggle,
        }}
      />
    </div>
  );
};
