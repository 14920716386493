import {
  TableFilterOption,
  TableOptions,
  TableProps,
} from "@/components/Table/types";
import {
  TableDateRangeOptions,
  TableExportOptions,
} from "@/components/Table/types/TableFilters";
import { useGetTableOptions } from "@/api/useGetTableOptions";

export const useTableOptions = (
  options: TableProps["options"]
): TableOptions => {
  const { url, filters } = options ?? {};
  const filtersExclusions = filters?.filtersToExclude;

  const { export_options, filter_options } = useGetTableOptions({
    url,
    filtersExclusions,
  });

  //Extract the date range from the options
  const tableDateRangeOptions: TableDateRangeOptions[] = filter_options
    ? Object.entries(filter_options)
        .filter(([_, { type }]) => type === "date") //Get only the date options
        .map(([key, { label, order }]) => ({ title: label, value: key, order })) //Convert to an array of objects with title and value
    : [];

  //Extract the table filters from the options
  const tableFilterOptions: TableFilterOption[] = filter_options
    ? Object.entries(filter_options)
        .filter(([_, { type }]) => type !== "date") //Remove the date options
        .map(([key, { values, label }]) => ({
          label,
          key,
          values,
        })) //Convert to an array of objects with label, key and values
    : [];

  //Extract the table download options from the options
  const tableExportOptions: TableExportOptions | null = export_options
    ? export_options
    : null;

  return {
    tableDateRangeOptions,
    tableExportOptions,
    tableFilterOptions,
  };
};
