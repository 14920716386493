import { UserPermissions } from "../../api/getUserPermissions/types";

export const updatePermissions = (
  userPermission: UserPermissions,
  setPermissions: React.Dispatch<React.SetStateAction<UserPermissions[]>>
) => {
  setPermissions((previousPermissions) => {
    let hasPermission = false;

    const updatedPermissions = previousPermissions.reduce<UserPermissions[]>(
      (acc, prevPermission) => {
        if (prevPermission.id === userPermission.id) {
          hasPermission = true; // Found the permission
          return acc; // Skip adding it to the new array
        }
        acc.push(prevPermission);
        return acc;
      },
      []
    );

    return hasPermission
      ? updatedPermissions
      : [...updatedPermissions, userPermission];
  });
};
