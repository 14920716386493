import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { handleCreateOrder } from "../common/utils/handleCreateOrder";

const url: string = getURL("api:internal-orders-canceled");
const tableOptionsUrl: string = getURL("api:internal-orders-schema-options");

export const CanceledOrders: FC = () => {
  return (
    <Table
      id={"facility_id"}
      sortBy="-canceled_at"
      onAddRecord={handleCreateOrder}
      name="Canceled Orders"
      options={{
        url: tableOptionsUrl,
        filters: {
          filtersToExclude: ["in_transit_at", "received_at", "order_status"],
        },
      }}
      searchable
      headers={[
        { title: "#", key: "id" },
        { title: "PLACED ON", key: "ordered_at", type: "date" },
        { title: "CANCELED ON", key: "canceled_at", type: "date" },
        { title: "TYPE", key: "order_type" },
        {
          title: "ITEMS",
          properties: { notSortable: true },
          key: "component_string",
        },
        { title: "BUYER", key: "buyer" },
        { title: "SELLER", key: "seller" },
        { title: "AUDIENCE", key: "audience" },
      ]}
      url={url}
      actions={[
        "order_activity",
        "add_order_comment",
        "edit_order",
        "flag_order",
      ]}
    />
  );
};
