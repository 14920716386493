import { useContext } from "react";

import { TableContext } from "@/components/Table/context";
import { TableFilterOption } from "@/components/Table/types";
import { useSearchParams } from "@/hooks/useSearchParams";

export const useTableFiltersLogic = (
  tableFilterOptions: TableFilterOption[]
) => {
  const { searchParams } = useSearchParams();

  const encodedActiveFilters = searchParams.get("filters");
  const { getSearchParams } = useContext(TableContext);
  const { removeAllFilters, removeFilter } = getSearchParams;

  const tableActiveFilters = encodedActiveFilters
    ? JSON.parse(decodeURIComponent(encodedActiveFilters))
    : [];

  const filters = tableActiveFilters.map(({ key, value }: any) => {
    //Object found inside the tableFilterOptions
    const filter = tableFilterOptions.find((filter) => filter.key === key);

    //Object found inside the values array from the founded filter inside tableFilterOptions
    const valueFromActive = filter?.values.find(
      (found) => found.value === value
    );

    const filterFound = {
      label: filter?.label,
      title: valueFromActive?.title,
    };

    return filterFound;
  });

  const thereIsNoFilters = !filters.length;

  return {
    filters,
    thereIsNoFilters,
    removeAllFilters,
    removeFilter,
  };
};
