import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

import { DEFAULT_PAGINATION_CURRENT_PAGE } from "@/components/Table/constants";
import { PaginatedResponse } from "@/types";

export type Filter = {
  key: string;
  value: string;
};

/**
 * Decodes filters from the query string
 */
const decodeFilters = (filtersString: string | null): Filter[] => {
  if (!filtersString) return [];
  return JSON.parse(decodeURIComponent(filtersString)) as Filter[];
};

/**
 * Encodes filters into a query string
 */
const encodeFilters = (filters: Filter[]): string => {
  return encodeURIComponent(JSON.stringify(filters));
};

/**
 * Adds a filter to the current URL
 */
const addFilter = (newFilter: Filter): void => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const filters = decodeFilters(urlSearchParams.get("filters")) || [];

  //Check if the filter is already in the array
  if (
    filters.some(
      (filter) => JSON.stringify(filter) === JSON.stringify(newFilter)
    )
  ) {
    toast("Filter already active", { type: "warning" });
    return;
  }

  //Remove the filter if the key already exists
  const index = filters.map((filter) => filter.key).indexOf(newFilter.key);
  if (index !== -1) filters.splice(index, 1);

  //Add filter
  filters.push(newFilter);

  //Update filters
  urlSearchParams.set("filters", encodeFilters(filters));

  //Reset pagination page to default
  urlSearchParams.set("page", DEFAULT_PAGINATION_CURRENT_PAGE.toString());

  //Update URL
  updateURL(urlSearchParams.toString());
};

/**
 * Removes a filter by value from the current URL
 */
const removeFilter = (keyToRemove: string): void => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const filters = decodeFilters(urlSearchParams.get("filters"));
  const updatedFilters = filters.filter((filter) => filter.key !== keyToRemove);

  //Update filters
  urlSearchParams.set("filters", encodeFilters(updatedFilters));

  //Reset pagination page to default
  urlSearchParams.set("page", DEFAULT_PAGINATION_CURRENT_PAGE.toString());

  //Update URL
  updateURL(urlSearchParams.toString());
};

/**
 * Removes all filters from the current URL
 */
const removeAllFilters = (): void => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete("filters");

  //Reset pagination page to default
  urlSearchParams.set("page", DEFAULT_PAGINATION_CURRENT_PAGE.toString());

  //Update URL
  updateURL(urlSearchParams.toString());
};

/**
 * Updates the browser's URL without reloading the page
 */
const updateURL = (queryString: string): void => {
  const baseUrl = window.location.origin + window.location.pathname;
  const newUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;
  window.history.pushState({}, "", newUrl);
};

export const activeFilters = <T extends Record<string, unknown>>(
  mutation: KeyedMutator<PaginatedResponse<T>> | undefined
) => ({
  addFilter: (newFilter: Filter) => {
    addFilter(newFilter);
    mutation?.(); //Update the table
  },
  removeFilter: (keyToRemove: string) => {
    removeFilter(keyToRemove);
    mutation?.();
  },
  removeAllFilters: () => {
    removeAllFilters();
    mutation?.();
  },
});
