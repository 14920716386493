import { axiosRequest } from "@root/src/hooks/axiosRequest";
import { getURL } from "@root/src/utils/getURL";
import useSWR from "swr";
import { PermissionType } from "../../types";

export const useGetPermissions = () => {
  const url: string = getURL("api:groups-list");

  const { data: response } = useSWR(url, (url) =>
    axiosRequest<PermissionType>({ url })
  );

  const results = response?.data?.results ?? [];

  return results;
};
