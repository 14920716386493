import { MdKeyboardArrowDown } from "react-icons/md";

import { DropDownSingleSelectProps } from "./types";
import { DropdownMenu } from "../DropdownMenu";
import { Icon } from "../Icon";
import { DropdownMenuVariants } from "../DropdownMenu/types";
import { useDropdownSingleSelectLogic } from "./utils/useDropdownSingleSelectLogic";
import { DropdownLabel } from "../DropdownMenu/utils/getDropdownMenuVariant/components/DropdownMenuItem/components/DropdownMenuLabel";

import "./scss/index.scss";

export const DropdownSingleSelect = <
  Value = string,
  Variant extends DropdownMenuVariants = undefined
>({
  options,
  selectedOption,
  label,
  loading,
  placeholder,
  disabled,
  size,
  className,
  ...dropdownMenuProps
}: DropDownSingleSelectProps<Value, Variant>) => {
  const {
    ref,
    clickedOutside,
    clickedOutsideToggle,
    thereAreNoOptions,
    buttonClassName,
    focusButtonOnClick,
    title,
  } = useDropdownSingleSelectLogic<Value, Variant>({
    options,
    selectedOption,
    placeholder,
    loading,
  });

  return (
    <div
      data-testid="single-select-dropdown"
      className={`single-select-dropdown-container ${className} ${
        disabled ? "disabled" : ""
      } ${size}`}
    >
      <DropdownLabel {...{ label, disabled }} />
      <button
        ref={ref}
        onClick={thereAreNoOptions ? undefined : clickedOutsideToggle}
        className={buttonClassName}
      >
        <span
          className={`single-select-dropdown-title ${
            disabled ? "disabled" : ""
          }`}
        >
          {title.toString()}
        </span>
        <Icon
          className="single-select-dropdown-icon"
          onClick={focusButtonOnClick}
          color="neutral"
          icon={MdKeyboardArrowDown}
          variant={"link"}
          disabled={disabled}
        />
      </button>
      {clickedOutside && !thereAreNoOptions ? (
        <DropdownMenu {...{ options, selectedOption, ...dropdownMenuProps }} />
      ) : null}
    </div>
  );
};
