import useSWR from "swr";

import { axiosRequest } from "../../hooks/axiosRequest";
import { TableOptionsFetchResponse } from "@/components/Table/types";
import { DEFAULT_TABLE_OPTIONS } from "./constants";
import { UseTableOptionsProps } from "./types";
import { getFiltersWithoutExclusions } from "./utils/getFiltersWithoutExclusions";

export const useGetTableOptions = ({
  url,
  filtersExclusions,
}: UseTableOptionsProps): TableOptionsFetchResponse => {
  const { data: response } = useSWR(url, (url) =>
    axiosRequest<null, TableOptionsFetchResponse>({ url })
  );

  if (!response) return DEFAULT_TABLE_OPTIONS;

  if (filtersExclusions) {
    const filters = response.data.filter_options;

    return {
      ...response.data,
      filter_options: getFiltersWithoutExclusions(
        filters,
        filtersExclusions as string[]
      ),
    };
  }

  return response.data;
};
