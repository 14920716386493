import { GetDropdownTitle } from "./types";

export const getDropdownTitle = <T>({
  loading,
  placeholder,
  optionFoundValue,
  thereAreNoOptions,
  selectedOption,
}: GetDropdownTitle<T>) => {
  if (loading) return "Loading options";
  if (thereAreNoOptions) return "No options";
  if (placeholder && !optionFoundValue && selectedOption === undefined)
    return placeholder;
  if (selectedOption || selectedOption === 0)
    return optionFoundValue || String(selectedOption);
  if (!optionFoundValue || !selectedOption) return "Select an option";

  return optionFoundValue;
};
