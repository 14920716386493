import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { FacilityAdminActiveType } from "./types/facilityAdminActive";
import { useModal } from "@/components/Table/hooks/useModal";
import { TableHeaders } from "@/components/Table/types/TableHeaders";
import { MailerModal } from "@/components/Table/components/BaseTable/hooks/useTableRows/components/TableRowItem/components/TableRowActions/components/GenerateActions/utils/useGetAction/components/Email/template/MailerModal";

export const FacilityAdminActive: FC = () => {
  const headers: TableHeaders = [
    { title: "FACILITY", key: "name" },
    { title: "LOCATION", key: "location" },
    { title: "TYPE", key: "facility_type" },
    {
      title: "PRIMARY CONTACT",
      key: ["primary_contact", "full_name"],
    },
    { title: "USERS", key: "user_count" },
    { title: "GROUP ADMINS", key: "group_admin_count" },
  ];

  const url: string = getURL("api:internal-facilities-list");
  const tableOptionsUrl: string = getURL(
    "api:internal-facilities-schema-options"
  );
  const { modal, handleOpenModal } = useModal(MailerModal);

  return (
    <>
      {modal}
      <Table<FacilityAdminActiveType>
        bulkActions={{
          primaryAction: {
            label: "Email",
            onClick: handleOpenModal,
            config: {
              type: "facility",
            },
          },
        }}
        id={"facility_id"}
        name="Active Facilities"
        searchable
        headers={headers}
        url={url}
        options={{
          url: tableOptionsUrl,
        }}
        actions={[
          "view_profile",
          "edit_facility",
          { current: "email", config: { type: "facility" } },
        ]}
      />
    </>
  );
};
