import { FC } from "react";
import { motion } from "motion/react";

import { Icon } from "../Icon";
import { ButtonProps } from "./types";

import "./scss/index.scss";

/**
 * {@link Button | Button}
 *
 * @param label - Text inside the component
 * @param size - Component size, options "x-small" | "small" | "medium" | "large"
 * @param color - Color type of the component, options "primary" | "success" | "destructive"
 * @param variant- Style in which the component will be rendered, options "filled" | "secondary" | "outlined" | "link"
 * @param leftIcon - Icon to be rendered inside the component
 * @param rightIcon - Icon to be rendered inside the component
 * @param onClick - Function to run on click
 * @param testID - string to assign the id when running unit tests
 * @param className - ClassName to add custom styles
 * @param disabled - Boolean to toggle disabled variant
 *
 * @returns JSX element, Button component
 *
 * @example
 * Simple usage:
 * ```
 * <Button
 *  onClick={testFunction}
 *  size={"small"}
 *  color={"primary"}
 *  variant={"primary"}
 * />
 * ```
 *
 * @example
 * With icons:
 * ```
 * <Button
 *  onClick={testFunction}
 *  size={"small"}
 *  color={"primary"}
 *  variant={"primary"}
 *  leftIcon={IoAccessibility}
 *  rightIcon={IoAccessibility}
 * />
 * ```
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4398 - Jira Story}
 *
 * {@link https://www.figma.com/file/z2WZdtAPhE6soo9OLzSm6k/Bloodbuy-Buttons?t=8gpgvaOgOx6TTqfV-0 - Figma design}
 */
export const Button: FC<ButtonProps> = ({
  leftIcon,
  rightIcon,
  label,
  onClick,
  size = "medium",
  color = "primary",
  variant = "filled",
  className = "",
  disabled,
  testID,
}): JSX.Element => {
  const customClassName: string = `button ${color}--${variant} ${size} ${className}`;
  const iconSize = size === "large" ? "medium" : "small";

  return (
    <motion.button
      whileHover={disabled ? undefined : { scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      data-testid={testID}
      onClick={onClick}
      className={customClassName}
      disabled={disabled}
    >
      <Icon
        {...{
          icon: leftIcon,
          size: iconSize,
          testID: "left-icon",
          variant: "link",
          color,
        }}
      />
      <span className="button-label">{label}</span>
      <Icon
        {...{
          icon: rightIcon,
          size: iconSize,
          testID: "right-icon",
          variant: "link",
          color,
        }}
      />
    </motion.button>
  );
};
