import { ItemsContainerItem } from "../../../../types";
import { GetFilteredSelectedItems } from "./types";

export const getFilteredSelectedItems = <
  Item extends ItemsContainerItem,
  FilterBy extends keyof Item
>({
  items,
  filterBy,
  getItemLabel,
  query,
  filterByProp,
}: GetFilteredSelectedItems<Item, FilterBy>) => {
  const normalizedQuery = query.toLowerCase().trim();
  const isFilteringByAll = filterBy === "All";

  return items?.filter((item) => {
    const itemLabel = getItemLabel(item).toLowerCase();
    const matchesQuery = itemLabel.includes(normalizedQuery);

    if (isFilteringByAll) return matchesQuery;

    const hasTag = !!item.tags.length;
    const matchesFilter = hasTag
      ? item.tags.includes(filterBy as string)
      : item[filterByProp as string] === filterBy;

    return matchesQuery && matchesFilter;
  });
};
