import { BlockListModal } from "../../modal/BlockListModal";
import { useInternalBlockedFacilities } from "../../modal/BlockListModal/hooks/useInternalBlockedFacilities";
import { InternalBlockingListProps } from "./types";

export const InternalBlockingList = ({
  facility,
  onCloseModal,
}: InternalBlockingListProps) => {
  const { is_blocking } = useInternalBlockedFacilities(facility.id) ?? {};

  return (
    <BlockListModal
      blockFacilities={is_blocking}
      currentFacility={facility}
      onCloseModal={onCloseModal}
      variant="blocked"
    />
  );
};
