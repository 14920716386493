import useSWR from "swr";
import { getURL } from "@/utils/getURL";
import { InternalBlockedFacilities } from "./types";
import { axiosRequest } from "@/hooks/axiosRequest";

export const useInternalBlockedFacilities = (
  facilityId: string
): InternalBlockedFacilities | undefined => {
  const url = getURL("api:internal-blocked-facilities-detail", {
    uuid: facilityId,
  });

  const { data: response } = useSWR(url, () => axiosRequest({ url }));
  const internalBlockedFacilities: InternalBlockedFacilities = response?.data;
  return internalBlockedFacilities;
};
