import { Button } from "@/components/Button";
import { BlockListModalContent } from "./components/blockListModalContent";
import { BlockListModalDescription } from "./components/blockListModalDescription";
import { BlockListModalHeader } from "./components/blockListModalHeader";
import { BlockListModalProps } from "./types";

import "./scss/index.scss";

export const BlockListModal = ({
  blockFacilities = [],
  currentFacility,
  onCloseModal,
  variant,
}: BlockListModalProps) => {
  const blockListCount = blockFacilities.length;
  const blockStatusLabel = {
    blocking: "blocked by",
    blocked: "blocking",
  }[variant];

  return (
    <div className="block-list-modal">
      <BlockListModalHeader {...{ currentFacility, blockStatusLabel }} />
      <BlockListModalDescription
        {...{
          blockListCount,
        }}
      />
      <BlockListModalContent {...{ blockFacilities }} />
      <Button
        onClick={onCloseModal}
        className="block-list-modal__button"
        label="Close"
      />
    </div>
  );
};
