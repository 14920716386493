import { AlertInfoProps } from "./types";
import { AlertInfoIcon } from "./components/AlertInfoIcon";
import { AlertInfoDetailsContainer } from "./components/AlertInfoDetailsContainer";

import "./scss/index.scss";

export const AlertInfo = ({
  icon,
  color,
  header,
  description,
  actions,
}: AlertInfoProps) => (
  <div
    data-testid="alert-info-container"
    className={`alert-info-container ${!header ? "center-align" : ""}`}
  >
    <AlertInfoIcon {...{ icon, color }} />
    <AlertInfoDetailsContainer {...{ header, description, actions, color }} />
  </div>
);
