import { BlockListModalContentProps } from "./types";
import "./scss/index.scss";

export const BlockListModalContent = ({
  blockFacilities,
}: BlockListModalContentProps) => (
  <ul className="block-list-modal__list">
    {!blockFacilities.length && (
      <li className="block-list-modal__list__item">No blocked facilities</li>
    )}

    {blockFacilities.map((facility) => (
      <li key={facility.id} className="block-list-modal__list__item">
        {facility.name}
      </li>
    ))}
  </ul>
);
