import { FC, cloneElement } from "react";
import { FiCheck } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { AnimatePresence, motion } from "motion/react";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { FiltersContainerProps } from "./types";
import { useFiltersContainerLogic } from "./hooks/useContainerLogic";
import { GENERIC_FADE_ANIMATION } from "@root/src/constants";
import { SingleOrSearchableDropdown } from "./components/singleOrSearchableDropdown";

import "./scss/index.scss";

export const FiltersContainer: FC<FiltersContainerProps> = ({
  trigger,
  tableFilterOptions,
}) => {
  const {
    ref,
    toggleOpenFilter,
    clickedOutside,
    oncloseModal,
    topFilterOptions,
    subHeaderOptions,
    dataHandler,
    data,
    submitFilters,
  } = useFiltersContainerLogic(tableFilterOptions);

  if (!tableFilterOptions || !tableFilterOptions.length) return null;

  return (
    <div ref={ref} className="filter-container">
      {cloneElement(trigger, {
        onClick: toggleOpenFilter,
      })}

      {clickedOutside ? (
        <AnimatePresence>
          <motion.div
            {...GENERIC_FADE_ANIMATION}
            className="filter-modal-container"
          >
            <div className="close-icon-container">
              <Icon
                icon={IoMdClose}
                variant={"link"}
                color="neutral"
                size="small"
                onClick={oncloseModal}
              />
            </div>
            <DropdownSingleSelect
              options={topFilterOptions}
              label="Filter by"
              onClick={({ title: label, value: key }) =>
                dataHandler({
                  key,
                  label,
                  title: "",
                  value: "",
                })
              }
              selectedOption={data.label}
            />
            <SingleOrSearchableDropdown
              {...{
                subHeaderOptions,
                data,
                dataHandler,
              }}
            />
            <Button
              label="Apply Filter"
              size={"medium"}
              color={"primary"}
              variant={"filled"}
              className={"flex-button"}
              leftIcon={FiCheck}
              disabled={!data.title}
              onClick={submitFilters}
              testID="apply-filter-button"
            />
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  );
};
